import { createSlice } from '@reduxjs/toolkit';

const toggleFormsSlice = createSlice({
  name: 'toggleForms',
  initialState: {
    isPaymentOpened: false,
    paymentType: '',
  },
  reducers: {
    setPaymentsType: (state, action) => {
      if (action.payload) {
        const { isPaymentOpened, paymentType } = action.payload;
        state.isPaymentOpened = isPaymentOpened;
        state.paymentType = paymentType;
      } else {
        state.isPaymentOpened = null;
        state.paymentType = null;
      }
    },
  },
});

export const { setPaymentsType } = toggleFormsSlice.actions;

export const selectPaymentsType = (state) => state.toggleForms;

export default toggleFormsSlice.reducer;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../config/axiosConfig.js';

import { Typography, TextField, Link } from '@mui/material';

import {
  CustomButton,
  CustomInputs,
  CustomAutocomplete,
} from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils.js';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries.js';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';
import TooltipButton from '../../utils/ui/TooltipComponent.jsx';
import { LINKS } from '../../utils/constants';

const CustomsDeclarationForm = ({ onClose, productItem, onSuccess }) => {
  const { t } = useTranslation();
  const { isSdScreen, isSgScreen, isSgMaxScreen } = useMediaQueries();

  const [countries, setCountries] = useState([]);

  const [originCountry, setOriginCountry] = useState(null);
  const [tariffNumber, setTariffNumber] = useState(null);
  const euTariffNumberHint =
    'https://taxation-customs.ec.europa.eu/customs-4/calculation-customs-duties/customs-tariff/eu-customs-tariff-taric_en';
  const tariffNumberHint = {
    AT: euTariffNumberHint,
    BE: euTariffNumberHint,
    BG: euTariffNumberHint,
    HR: euTariffNumberHint,
    CY: euTariffNumberHint,
    CZ: euTariffNumberHint,
    DK: euTariffNumberHint,
    EE: euTariffNumberHint,
    FI: euTariffNumberHint,
    FR: euTariffNumberHint,
    DE: euTariffNumberHint,
    GR: euTariffNumberHint,
    HU: euTariffNumberHint,
    IE: euTariffNumberHint,
    IT: euTariffNumberHint,
    LV: euTariffNumberHint,
    LT: euTariffNumberHint,
    LU: euTariffNumberHint,
    MT: euTariffNumberHint,
    NL: euTariffNumberHint,
    PL: euTariffNumberHint,
    PT: euTariffNumberHint,
    RO: euTariffNumberHint,
    SK: euTariffNumberHint,
    SI: euTariffNumberHint,
    ES: euTariffNumberHint,
    SE: euTariffNumberHint,
    UK: 'https://www.trade-tariff.service.gov.uk/a-z-index/a',
    US: 'https://hts.usitc.gov/',
    CA: 'https://www.cbsa-asfc.gc.ca/trade-commerce/tariff-tarif/menu-eng.html',
    NO: 'https://www.toll.no/en/corporate/norwegian-customs-tariff/',
  };
  const [contentsExplanation, setContentsExplanation] = useState(null);
  const [contentsType, setContentsType] = useState(null);
  const contentsTypes = ['Documents', 'Gift', 'Sample', 'Merchandise', 'Returned Goods', 'Other'];

  const [contentsTypeError, setContentsTypeError] = useState('');
  const [contentsExplanationError, setContentsExplanationError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [tariffNumberError, setTariffNumberError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();

  const formTitleRef = useRef(null);

  const fetchCountries = useCallback(async () => {
    try {
      const countryResponse = await axios.get('country');
      setCountries(countryResponse.data);
    } catch (error) {
      showSnackbar(error);
    }
  }, []);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const handleCustomsDeclaration = async () => {
    const fieldIsRequiredMsg = `${t('Field is required')}`;
    let hasError = false;
    let fieldsToCheck = [
      { value: originCountry, setError: setCountryError },
      { value: contentsType, setError: setContentsTypeError },
      { value: tariffNumber, setError: setTariffNumberError },
    ];

    if (contentsType === 'Other')
      fieldsToCheck.push({ value: contentsExplanation, setError: setContentsExplanationError });

    fieldsToCheck.forEach(({ value, setError }) => {
      if (!value || (typeof value === 'string' && !value.trim())) {
        setError(fieldIsRequiredMsg);
        hasError = true;
      }
    });

    if (hasError) {
      formTitleRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }
    try {
      setIsLoading(true);
      await axios.post('delivery/create-declaration', {
        deliveryId: productItem.customer.delivery,
        productId: productItem._id,
        originCountry: originCountry.iso,
        tariffNumber,
        contentsExplanation,
        contentsType,
      });
      onSuccess();
    } catch (error) {
      showSnackbar(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormTemplate
        open={true}
        onClose={onClose}
        dialogTitle={
          <>
            <Typography
              ref={formTitleRef}
              align="center"
              className="px-4 sm:px-8"
              fontSize={isSgScreen ? 24 : isSdScreen ? 20 : 18}
              fontWeight={500}
              width="100%"
            >
              {t('Customs Declaration')}
            </Typography>
          </>
        }
        dialogContent={
          <>
            <div className="flex justify-between max-[435px]:flex-col max-[435px]:items-center mt-4 px-4 w-full">
              <div className="flex justify-between items-center" style={{ width: isSgMaxScreen ? '100%' : '49%' }}>
                <CustomAutocomplete
                  value={contentsType}
                  onChange={(event, newValue) => {
                    setContentsType(newValue);
                    setContentsTypeError('');
                  }}
                  options={contentsTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Contents Type')}
                      helperText={contentsTypeError ? contentsTypeError : ' '}
                      error={!!contentsTypeError}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
                <TooltipButton
                  marginBottom={25}
                  title={t(
                    'The type of contents in this shipment. This may impact import duties or customs treatment.'
                  )}
                />
              </div>
              <div className="flex justify-between items-center" style={{ width: isSgMaxScreen ? '100%' : '49%' }}>
                <CustomInputs
                  placeholder={t('Contents Explanation')}
                  variant="outlined"
                  helperText={contentsExplanationError ? contentsExplanationError : "'Other' Contents Type only"}
                  error={!!contentsExplanationError}
                  value={contentsExplanation}
                  disabled={contentsType !== 'Other'}
                  onChange={(e) => {
                    setContentsExplanation(e.target.value);
                    setContentsExplanationError('');
                  }}
                />
                <TooltipButton
                  marginBottom={25}
                  title={t("Explanation for contents (required if the Contents Type is provided as 'Other')")}
                />
              </div>
            </div>
            <Typography fontSize={18} mt={4} mb={3}>
              {t('Customs Item Info')}
            </Typography>
            <div className="flex justify-between max-[435px]:flex-col max-[435px]:items-center px-4 w-full">
              <div className="flex justify-between items-center" style={{ width: isSgMaxScreen ? '100%' : '49%' }}>
                <CustomAutocomplete
                  value={originCountry}
                  options={countries}
                  getOptionLabel={(countries) => countries?.name}
                  onChange={(e, newValue) => {
                    setOriginCountry(newValue);
                    setCountryError('');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Origin Country')}
                      onChange={(e, newValue) => {
                        setOriginCountry(newValue);
                        setCountryError('');
                      }}
                      helperText={countryError ? countryError : ' '}
                      error={!!countryError}
                    />
                  )}
                />
                <TooltipButton marginBottom={25} title={t('The country where this item originated.')} />
              </div>
              <div className="flex justify-between items-center" style={{ width: isSgMaxScreen ? '100%' : '49%' }}>
                <CustomInputs
                  placeholder={t('Harmonized Tariff Code')}
                  variant="outlined"
                  value={tariffNumber}
                  onChange={(e) => {
                    setTariffNumber(e.target.value);
                    setTariffNumberError('');
                  }}
                  helperText={
                    tariffNumberError ? (
                      tariffNumberError
                    ) : tariffNumberHint[productItem?.delivery?.country] ? (
                      <span>
                        <Link href={tariffNumberHint[productItem?.delivery?.country]} target="_blank">
                          Click here
                        </Link>{' '}
                        to check HTC guide
                      </span>
                    ) : (
                      ' '
                    )
                  }
                  error={!!tariffNumberError}
                />
                <TooltipButton marginBottom={25} title={t('The Harmonized Tariff Code of this item.')} />
              </div>
            </div>
            <Typography fontWeight={500} fontSize={isSgMaxScreen ? 14 : 16} mt={3} px={2} align="center">
              {t('In case you need any help with customs declaration, please contact us via email:')}
              <a className="underline ml-2" href={`mailto:${LINKS.EMAIL}`}>
                {LINKS.EMAIL}
              </a>
            </Typography>
          </>
        }
        dialogContentPadding={0}
        dialogActions={
          <>
            <CustomButton
              variant="contained"
              width={10}
              style={{
                width: isSdScreen ? null : 180,
              }}
              onClick={handleCustomsDeclaration}
              disabled={isLoading}
            >
              <LoadingIndicator isLoading={isLoading} value={'create declaration'} />
            </CustomButton>
          </>
        }
      />
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default CustomsDeclarationForm;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Divider, Card, CardMedia, Skeleton, Box, CircularProgress } from '@mui/material';

import axios from '../../config/axiosConfig';
import { CustomButton, CustomInputs } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import { CustomSkeleton } from './StyledSkeletonComponent.jsx';
import PaymentForm from '../forms/payment/PaymentForm.jsx';
import SwiperComponent from '../../utils/ui/SwiperComponent.jsx';
import InputWithCurrency from '../../utils/ui/InputWithCurrency.jsx';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import { formatDateTime } from '../../utils/functions/dateTimeFormatter';
import { formatNumberWithTwoDecimals } from '../../utils/functions/formatNumberWithTwoDecimals';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';
import RednderPrice from './RenderProductPrice.jsx';
import BidsTable from './BidTableComponent.jsx';
import CardComponent from './CardTicketComponent.jsx';
import ProductAuctionRaffleDialogTexts from './ProductAuctionRaffleDialogTexts.js';
import WinnerUserCard from './WinnerObserverCardComponents.jsx';
import { selectUser } from '../../store/features/userSlice';
import { setPaymentsType, selectPaymentsType } from '../../store/features/toggleFormsSlice';
import UnfulfilledItemForm from './UnfulfilledItemForm.jsx';
import CustomsDeclarationForm from './CustomsDeclarationForm.jsx';

import locationIcon from '../../assets/icons/locationIcon.svg';
import watchTimeIcon from '../../assets/icons/watchTimeIcon.svg';
import watchlistIcon from '../../assets/icons/watchlistIcon.svg';
import ticketIcon from '../../assets/icons/ticketIcon.svg';
import congratulationsIcon from '../../assets/icons/congratulationsIcon.svg';
import messagesIcon from '../../assets/icons/messagesIcon.svg';
import reservePriceIcon from '../../assets/icons/myAuctionsAndRafflesIcon.svg';
import verificationIcon from '../../assets/icons/verificationIcon.svg';

function ProductComponent() {
  const { hash } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isSgMaxScreen, isSdScreen, isSgScreen, isMdScreen, isLgScreen } = useMediaQueries();

  const user = useSelector(selectUser);
  const paymentsType = useSelector(selectPaymentsType);
  const [productData, setProductData] = useState({});
  const [openPaymentForm, setOpenPaymentForm] = useState(
    paymentsType.isPaymentOpened && user ? paymentsType.isPaymentOpened : false
  );
  const [paymentType, setPaymentType] = useState(paymentsType.paymentType ? paymentsType.paymentType : '');
  const [loadingData, setLoadingData] = useState(true);
  const [loadingComfirmEndAuctionOrRaffleBnt, setLoadingComfirmEndAuctionOrRaffleBnt] = useState(false);
  const [loadingCancelActionOrRaffleBtn, setLoadingCancelActionOrRaffleBtn] = useState(false);
  const [loadingOpenDisputeBtn, setLoadingOpenDisputeBtn] = useState(false);
  const [isRaffle, setIsRaffle] = useState(false);

  const [disputeMessage, setDisputeMessage] = useState('');
  const [disputeMessageError, setDisputeMessageError] = useState('');
  const [buyNowOrder, setBuyNowOrder] = useState('');
  const [winnerNumberValue, setWinnerNumberValue] = useState('');
  const [winnerUserNumber, setWinnerUserNumber] = useState('');

  const [openSuccessForm, setOpenSuccessForm] = useState(false);
  const [openEndRaffleForm, setOpenEndRaffleForm] = useState(false);
  const [openEndAuctionForm, setOpenEndAuctionForm] = useState(false);
  const [isConfirmEndRaffle, setIsConfirmEndRaffle] = useState(false);
  const [isConfirmEndAuction, setIsConfirmEndRAuction] = useState(false);
  const [isAuctionOrRaffleCanceled, setIsAuctionOrRaffleCanceled] = useState(false);
  const [isOpenDisputeForm, setIsOpenDisputeForm] = useState(false);
  const [recentlyViewedProductData, setRecentlyViewedProductData] = useState(Array(2).fill(null));
  const [isLoadingRecentlyViewedData, setIsLoadingRecentlyViewedData] = useState(true);

  const [isShowFulfilledItem, setIsShowFulfilledItem] = useState(false);
  const [isFulfilmentForm, setIsFulfilmentForm] = useState(false);

  const [isShowCustomsDeclaration, setIsShowCustomsDeclaration] = useState(false);

  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);

  const handleClickBuy = (paymentType) => {
    dispatch(setPaymentsType({ isPaymentOpened: true, paymentType }));
    if (user.role) {
      setPaymentType(paymentType);
      setOpenPaymentForm(true);
    } else {
      dispatch(setPaymentsType({ isPaymentOpened: false, paymentType }));
      navigate('/login');
    }
  };

  const handleClosePaymentForm = () => {
    setOpenPaymentForm(false);
    dispatch(setPaymentsType({ isPaymentOpened: false, paymentType: null }));
  };

  const handleCancelAuctionOrRaffle = () => {
    setLoadingCancelActionOrRaffleBtn(true);
    axios
      .put('product/' + productData?._id, {
        status: 'canceled',
      })
      .then((response) => {
        showSnackbar(null, `${isRaffle ? 'Raffle' : 'Auction'} canceled`, 'success');
        fetchData();
        setIsAuctionOrRaffleCanceled(false);
        setLoadingCancelActionOrRaffleBtn(false);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const handleComfirmEndAuctionOrRaffle = () => {
    setLoadingComfirmEndAuctionOrRaffleBnt(true);
    axios
      .post(`product/${productData?._id}/finish`)
      .then((response) => {
        setWinnerNumberValue(isRaffle ? response?.data?.ticket : response?.data?.bid);
        setWinnerUserNumber(response?.data?.user);
        fetchData();
        isRaffle ? setIsConfirmEndRaffle(true) : setIsConfirmEndRAuction(true);
        setLoadingComfirmEndAuctionOrRaffleBnt(false);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const handleAddToWatchlist = () => {
    axios
      .post('watch', {
        product: productData?._id,
      })
      .then((response) => {
        showSnackbar(null, 'Successfully added to Watchlist!', 'success');
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const handleOpenDispute = () => {
    if (!disputeMessage) {
      setDisputeMessageError('Field is required');
      return;
    }
    setLoadingOpenDisputeBtn(true);
    const disputeSide = productData?.user?._id === user?._id ? 'seller' : 'winner';
    axios
      .put(`order/${customer._id}/dispute`, {
        message: disputeMessage,
        side: disputeSide,
      })
      .then(() => {
        showSnackbar(null, 'Dispute opened!', 'success');
        fetchData();
        setIsOpenDisputeForm(false);
        setLoadingOpenDisputeBtn(false);
      })
      .catch((error) => {
        showSnackbar(error);
      });
  };

  const handleCreateChat = () => {
    axios
      .post(`chat`, {
        users: [user?._id, productData?.user?._id],
      })
      .then(() => {
        showSnackbar(null, 'Create chat', 'success');
      })
      .catch((error) => {
        showSnackbar(null, error, 'info');
      })
      .finally(() => {
        navigate('/chat');
      });
  };

  const fetchRecentlyViewedData = useCallback(() => {
    setIsLoadingRecentlyViewedData(true);
    axios
      .get(`product/view/product?viewCurrency=${user.currency}`)
      .then(function (response) {
        const recentlyViewedProductData = response.data
          .map((item) => item?.product)
          .filter((item) => item?.productInfo?.kind === 'Raffle');
        setRecentlyViewedProductData(recentlyViewedProductData);
        setIsLoadingRecentlyViewedData(false);
      })
      .catch(function (error) {
        showSnackbarRef.current(error);
      });
  }, [user.currency]);

  const fetchData = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const isSuccessOrder = searchParams.get('order');
    const orderType = searchParams.get('type');
    if (hash) {
      setLoadingData(true);
      const setViewedProduct = async (id) => {
        try {
          await axios.post(`product/view/${id}`);
        } catch (error) {
          showSnackbarRef.current(error);
        }
      };
      axios
        .get('product/' + hash + `?viewCurrency=${user.currency}`)
        .then(function (response) {
          setProductData(response.data);
          setIsRaffle(response?.data?.productInfo?.kind === 'Raffle');
          if (user?.role && response?.data?.productInfo?.kind !== 'Auction') {
            fetchRecentlyViewedData();
            setViewedProduct(response.data?._id);
          }
          if (isSuccessOrder === 'success') {
            const buyNowOrder = response.data?.orderList?.find(
              (order) => order?.orderStripeId === response.data?.customer?.orderStripeId
            );
            setOpenSuccessForm(true);
            setBuyNowOrder(buyNowOrder);
          } else if (isSuccessOrder === 'error') {
            showSnackbarRef.current();
          }
          setPaymentType(orderType);
          setLoadingData(false);
        })
        .catch(function (error) {
          showSnackbarRef.current(error);
        });
    }
  }, [hash, user.currency, user.role, fetchRecentlyViewedData, location.search]);

  useEffect(() => {
    fetchData();
  }, [fetchData, location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const orderType = searchParams.get('type');
    setPaymentType(paymentsType.paymentType ? paymentsType.paymentType : orderType);
  }, [paymentType, paymentsType.paymentType, location.search]);

  const {
    productInfo,
    title,
    description,
    currency,
    country,
    end,
    media,
    converter,
    tickets,
    totalBids,
    orderList,
    status,
    customer,
    delivery,
  } = productData;

  const isUnfulfilled = status === 'unfulfilled';
  const isFulfilled = status === 'fulfilled';
  const isPay = status === 'pay';
  const isPaid = status === 'paid';
  const isAccepted = status === 'accepted';
  const isDisputed = status === 'disputed';
  const isCanceled = status === 'canceled';
  const isWinner = isUnfulfilled || isFulfilled || isPay || isPaid || isDisputed;
  const isObserver = user?._id !== customer?.user?._id && productData?.user?._id !== user?._id;
  const isObserverAndWinner = isObserver && isWinner;
  const totalTicketsSold = productInfo?.total_amount - productInfo?.amount;
  const mediaArray = Array.isArray(media) ? media.map((img) => ({ img })) : [null];

  const acceptedOrders = orderList?.filter(
    (order) =>
      order?.status === 'accepted' ||
      order?.status === 'unfulfilled' ||
      order?.status === 'fulfilled' ||
      order?.status === 'disputed' ||
      order?.status === 'finished' ||
      (isCanceled && order?.status === 'rejected')
  );

  const userTickets = tickets?.find((ticket) => ticket?.user === user?._id);
  const isUserHasBid = !!acceptedOrders?.find((order) => order?.user?._id === user?._id);

  const highestBid = formatNumberWithTwoDecimals(acceptedOrders?.[0]?.converter?.price);

  const acceptedOrderList = orderList?.filter(
    (order, index) =>
      order?.status === 'accepted' ||
      order?.status === 'unfulfilled' ||
      order?.status === 'fulfilled' ||
      order?.status === 'disputed' ||
      order?.status === 'finished' ||
      (order?.status === 'rejected' &&
        order?.converter?.price <= highestBid &&
        orderList?.findIndex((o) => o?.converter?.price === order?.converter?.price) === index)
  );

  const winnerUserTicketNumber = productInfo?.winnerTicket;

  const [loadingConfirmReceivalBtn, setLoadingConfirmReceivalBtn] = useState(false);
  const [isConfirmReceivalForm, setIsConfirmReceivalForm] = useState(false);

  const handleConfirmReceival = () => {
    setLoadingConfirmReceivalBtn(true);
    axios
      .post(`order/${customer?._id}/finish`)
      .then(() => {
        showSnackbar(null, 'Receival confirmed!', 'success');
        setIsConfirmReceivalForm(false);
        fetchData();
        setLoadingConfirmReceivalBtn(false);
      })
      .catch((error) => {
        showSnackbar(error);
      });
  };

  return (
    <>
      <div className="mb-20 px-2">
        <Grid container spacing={2} className="max-[435px]:pt-4  pb-16 max-[435px]:pl-0 min-[435px]:px-4">
          <Grid
            item
            xs={isMdScreen ? 6 : 12}
            style={{ justifyContent: 'center' }}
            className={`${isObserverAndWinner && 'opacity-30'}`}
          >
            <SwiperComponent
              data={mediaArray}
              isOnlyImages={true}
              isLoading={loadingData}
              isThumb={isMdScreen ? true : false}
              slidePerView={1}
            />
            {mediaArray.length === 0 && (
              <>
                <div className="flex justify-center">
                  <Card
                    className="flex items-center justify-center"
                    sx={{
                      borderRadius: '0.5rem',
                      maxHeight: 601,
                    }}
                    style={{
                      minWidth: '90%',
                      margin: '0 5%',
                    }}
                  >
                    {loadingData ? (
                      <Skeleton
                        sx={{
                          height: isMdScreen ? '35vw' : isSdScreen ? '65vw' : '80vw',
                          width: '100%',
                        }}
                        animation="wave"
                        variant="rectangular"
                      />
                    ) : (
                      <>
                        <CardMedia
                          component="div"
                          sx={{
                            height: isMdScreen ? '35vw' : isSdScreen ? '65vw' : '80vw',
                            maxHeight: 601,
                          }}
                        />
                        {t('No image')}
                      </>
                    )}
                  </Card>
                </div>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={isMdScreen ? 6 : 12}
            sx={{ mt: isMdScreen ? 1 : 4, px: 2, margin: isMdScreen ? null : '0 5%' }}
          >
            <Typography fontSize={16} fontWeight={300} className="flex">
              {loadingData ? (
                <CustomSkeleton />
              ) : (
                <>
                  {productInfo?.kind} {t('by')}:{' '}
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 400,
                      width: 'fit-content',
                      position: 'relative',
                      '&::after': {
                        content: "''",
                        display: 'inline-block',
                        width: '20px',
                        height: '20px',
                        backgroundImage: `${productData?.user?.verification === 'verified' ? `url(${verificationIcon})` : 'none'}`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        position: 'absolute',
                        top: '-5px',
                        right: '-25px',
                      },
                    }}
                    ml={0.5}
                  >
                    {productData?.user?.firstName} {productData?.user?.lastName}
                  </Box>
                </>
              )}
            </Typography>
            <div className={`${isObserverAndWinner && 'opacity-30'}`}>
              <Typography
                fontSize={24}
                fontWeight={500}
                sx={{
                  wordWrap: 'break-word',
                }}
                mt={1}
              >
                {loadingData ? <CustomSkeleton /> : <>{title}</>}
              </Typography>
              <div className="flex flex-row items-center mt-2">
                {!loadingData && <img src={locationIcon} alt="Location Icon" style={{ width: '26px' }} />}
                <Typography fontWeight={200} ml={2}>
                  {loadingData ? (
                    <CustomSkeleton
                      sx={{
                        fontSize: '20rem',
                      }}
                    />
                  ) : (
                    <>{country?.name}</>
                  )}
                </Typography>
              </div>
              <Typography
                fontSize={18}
                fontWeight={300}
                sx={{
                  wordWrap: 'break-word',
                }}
                my={3}
              >
                {loadingData ? <CustomSkeleton /> : <>{description}</>}
              </Typography>
            </div>
            <div className="flex flex-row items-center">
              {!loadingData && <img src={watchTimeIcon} alt="Timer Icon" style={{ width: '26px' }} />}
              <Typography fontSize={18} fontWeight={200} sx={{ ml: 2 }}>
                {loadingData ? (
                  <CustomSkeleton
                    width="100%"
                    sx={{
                      fontSize: '80rem',
                      mt: 2,
                    }}
                  />
                ) : (
                  <>
                    {isRaffle ? `${t('Raffle')}` : `${t('Auction')}`}{' '}
                    {isUnfulfilled || isFulfilled || isPay ? (
                      'ended!'
                    ) : isCanceled ? (
                      'cancelled!'
                    ) : (
                      <>
                        {t('ends in')} {formatDateTime(end, true)?.timeToEnd}
                      </>
                    )}
                  </>
                )}
              </Typography>
            </div>
            {!!converter?.reservePrice && productData?.user?._id === user?._id && (
              <div className="flex flex-row items-center mt-2">
                {!loadingData && <img src={reservePriceIcon} alt="Reserve Price Icon" style={{ width: '26px' }} />}
                <Typography fontSize={18} fontWeight={200} sx={{ ml: 2 }}>
                  {loadingData ? (
                    <CustomSkeleton
                      width="100%"
                      sx={{
                        fontSize: '80rem',
                        mt: 2,
                      }}
                    />
                  ) : (
                    <>{t(`Reserve price: ${converter?.currency?.abbr_name} ${converter?.reservePrice}`)}</>
                  )}
                </Typography>
              </div>
            )}
            {productData?.user?._id !== user?._id ? (
              <>
                <div className={`flex flex-col mt-2 ${isObserverAndWinner && 'opacity-30'}`}>
                  {loadingData ? (
                    <CustomSkeleton width="30%" />
                  ) : (
                    <>
                      <div className="flex items-center mb-2">
                        <img src={watchlistIcon} alt="Eyes Icon" style={{ width: '26px' }} />
                        <button style={{ marginLeft: '16px' }} onClick={handleAddToWatchlist} disabled={!user?.role}>
                          <Typography fontSize={18} fontWeight={300} className="underline">
                            {t('Add to watchlist')}
                          </Typography>
                        </button>
                      </div>
                      {user?._id && (
                        <>
                          <div className="flex items-center">
                            <img src={messagesIcon} alt="Chat Icon" style={{ width: '26px' }} />
                            <button style={{ marginLeft: '16px' }} onClick={handleCreateChat}>
                              <Typography fontSize={18} fontWeight={300} className="underline" width={'100%'}>
                                {t('Contact seller')}
                              </Typography>
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={`mt-8 ${isObserverAndWinner && 'opacity-30'}`}>
                  {loadingData ? (
                    <CustomSkeleton />
                  ) : (
                    <>
                      <div className="flex">
                        <Typography fontSize={19} fontWeight={300}>
                          {isRaffle ? (
                            `Price per Ticket${isWinner ? ' was' : ''}:`
                          ) : (
                            <>
                              {t('Last highest bid')}:
                              <span className="font-medium ml-2">
                                {acceptedOrderList?.length > 0 ? (
                                  <>{`${converter?.currency?.abbr_name} ${highestBid}`}</>
                                ) : (
                                  'None'
                                )}
                              </span>
                            </>
                          )}
                        </Typography>
                        {isRaffle && (
                          <RednderPrice
                            price={productInfo?.price}
                            currency={currency}
                            defaultCurrency={user.currency}
                            converter={converter}
                            isRaffle={isRaffle}
                          />
                        )}
                      </div>
                    </>
                  )}
                  {loadingData ? (
                    <CustomSkeleton />
                  ) : (
                    <>
                      <Typography component="div" fontSize={19} fontWeight={300} className="flex">
                        {isRaffle
                          ? `${t('Available Tickets')}${isWinner ? ' were' : ''}:`
                          : productInfo?.buy_now && `${t('Buy it now')}:`}{' '}
                        <span style={{ fontWeight: 500 }} className={isRaffle ? 'ml-2' : ''}>
                          {isRaffle ? (
                            <>
                              {productInfo?.amount}{' '}
                              {isWinner && (
                                <>
                                  <span className="font-light">out of</span> {productInfo?.total_amount}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {productInfo?.buy_now && (
                                <RednderPrice
                                  price={productInfo?.buy_now}
                                  currency={currency}
                                  defaultCurrency={user.currency}
                                  converter={converter}
                                  isRaffle={isRaffle}
                                />
                              )}
                            </>
                          )}
                        </span>
                      </Typography>
                      {productInfo?.minimum && acceptedOrderList?.length === 0 && (
                        <>
                          <Typography component="div" fontSize={19} fontWeight={300} className="flex">
                            {t('Minimal bid')}:
                            <span style={{ fontWeight: 500 }}>
                              <RednderPrice
                                price={productInfo?.minimum}
                                currency={currency}
                                defaultCurrency={user.currency}
                                converter={converter}
                                minimal={true}
                              />
                            </span>
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="flex flex-col items-center md:items-start">
                  {!isWinner && (
                    <>
                      {loadingData ? (
                        <CustomSkeleton sx={{ height: 50, width: 300, mt: 4 }} animation="wave" variant="rounded" />
                      ) : (
                        <>
                          <CustomButton
                            onClick={() => {
                              handleClickBuy(isRaffle ? 'buyTicket' : 'bid');
                            }}
                            variant="contained"
                            sx={{ px: 6, mt: 4 }}
                            disabled={!isAccepted || (isRaffle && productInfo?.amount <= 0)}
                          >
                            <div style={{ flex: 1 }}>{isRaffle ? `${t('buy ticket')}` : `${t('bid')}`}</div>
                          </CustomButton>
                          {!isRaffle && productInfo?.buy_now && (
                            <CustomButton
                              onClick={() => {
                                handleClickBuy('buyNow');
                              }}
                              variant="contained"
                              sx={{ px: 6, mt: 4 }}
                              disabled={isUserHasBid || !isAccepted || productInfo?.overdue}
                            >
                              {t('buy it now')}
                            </CustomButton>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {isWinner && customer && (
                    <>
                      {user?._id === customer?.user?._id && (
                        <Card
                          className="mt-6 p-6 w-full"
                          style={{
                            borderRadius: '0.75rem',
                            maxWidth: 640,
                          }}
                          sx={{ minHeight: 240 }}
                        >
                          <div className="flex flex-col w-full justify-between items-center">
                            <>
                              <Typography className="flex flex-row" align="center" fontSize={24}>
                                <img
                                  src={congratulationsIcon}
                                  alt="Congratulations Icon"
                                  style={{ width: 30, height: 30 }}
                                />
                                <span className="px-4">{t('WINNER WINNER CHICKEN DINNER')}</span>
                                <img
                                  src={congratulationsIcon}
                                  alt="Congratulations Icon"
                                  style={{ width: 30, height: 30 }}
                                />
                              </Typography>
                              <div className="mt-4">
                                <Typography align="center" fontSize={20}>
                                  {isRaffle ? 'Ticket number' : 'Bid'}
                                </Typography>
                                <CardComponent
                                  widht={'30vw'}
                                  value={
                                    !isRaffle
                                      ? acceptedOrderList?.[0]?.converter?.currency?.abbr_name + ' ' + highestBid
                                      : winnerUserTicketNumber
                                  }
                                />
                              </div>
                              <div className="mt-4">
                                <Typography align="center" fontSize={20}>
                                  {isRaffle ? t('Ticket Owner') : t('from')}
                                </Typography>
                                <CardComponent
                                  widht={'30vw'}
                                  value={
                                    <>
                                      <div className="flex flex-row">
                                        <img
                                          src={congratulationsIcon}
                                          alt="Congratulations Icon"
                                          style={{ width: 30, height: 30 }}
                                        />
                                        <span className="px-4">{t('You')}</span>
                                        <img
                                          src={congratulationsIcon}
                                          alt="Congratulations Icon"
                                          style={{ width: 30, height: 30 }}
                                        />
                                      </div>
                                    </>
                                  }
                                />
                              </div>
                              {isFulfilled && (
                                <>
                                  <div className="mt-4">
                                    <Typography align="center" fontSize={20}>
                                      {t(` ${isRaffle ? 'Raffle' : 'Auction'} was shipped:`)}
                                    </Typography>
                                    <div className="mt-4">
                                      <CardComponent widht={'30vw'} value={delivery?.trackingProvider} />
                                    </div>
                                    <div className="mt-2">
                                      <CardComponent widht={'30vw'} value={delivery?.trackingNumber} />
                                    </div>
                                  </div>
                                </>
                              )}
                              {productData?.rates && !productData?.rates?.length && !isRaffle && (
                                <Typography sx={{ marginTop: 6 }} align="center" fontSize={20}>
                                  {t(
                                    'Looks like there are no available options for your shipment. Please contact support using button in the right bottom corner or submit a dispute to involve Support manager in manual delivery.'
                                  )}
                                </Typography>
                              )}
                              <div className={'mt-6 flex flex-row w-full justify-center'}>
                                {productData?.rates && !delivery?.trackingNumber && !isRaffle && (
                                  <CustomButton
                                    disabled={!productData?.rates?.length}
                                    style={{
                                      widht: '90%',
                                      maxWidth: '50%',
                                    }}
                                    onClick={() => {
                                      setIsFulfilmentForm(true);
                                      setIsShowFulfilledItem(true);
                                    }}
                                  >
                                    {t('confirm shipment')}
                                  </CustomButton>
                                )}
                                {isFulfilled && delivery?.trackingUrl && (
                                  <Link
                                    to={delivery?.trackingUrl}
                                    target="_blank"
                                    style={{
                                      widht: '50%',
                                      maxWidth: '50%',
                                    }}
                                  >
                                    <CustomButton
                                      style={{
                                        widht: '90%',
                                        maxWidth: '90%',
                                      }}
                                    >
                                      {t('track shipment')}
                                    </CustomButton>
                                  </Link>
                                )}
                                {isFulfilled && isRaffle && delivery?.customShipping && (
                                  <CustomButton
                                    style={{
                                      widht: '50%',
                                      maxWidth: '50%',
                                    }}
                                    onClick={() => {
                                      setIsConfirmReceivalForm(true);
                                    }}
                                  >
                                    {t('confirm receival')}
                                  </CustomButton>
                                )}
                                {(isFulfilled || isUnfulfilled || isPaid || isDisputed) && (
                                  <CustomButton
                                    sx={{ ml: isUnfulfilled || isFulfilled ? 2 : 0 }}
                                    onClick={() => {
                                      setIsOpenDisputeForm(true);
                                    }}
                                    disabled={isDisputed || isPaid}
                                    style={{
                                      widht: '50%',
                                      maxWidth: '50%',
                                    }}
                                  >
                                    {t(`${isDisputed ? 'opened' : 'open'} dispute`)}
                                  </CustomButton>
                                )}
                              </div>
                            </>
                          </div>
                        </Card>
                      )}
                    </>
                  )}
                  {isObserverAndWinner && customer && isRaffle && (
                    <div className="mt-12 w-full flex justify-center md:justify-start">
                      <WinnerUserCard
                        {...{
                          customer,
                          isRaffle,
                          acceptedOrderList,
                          highestBid,
                          winnerUserTicketNumber,
                        }}
                      />
                    </div>
                  )}
                </div>
                {userTickets && isWinner && !customer && (
                  <>
                    <Card
                      className="mt-4 py-2 px-6 flex flex-col justify-evenly items-center xl:px-10"
                      style={{
                        borderRadius: '0.75rem',
                      }}
                      sx={{ minHeight: 240 }}
                    >
                      <Typography fontSize={20}>{t('Raffle ended without a winner')}</Typography>
                    </Card>
                  </>
                )}
                {userTickets && (
                  <>
                    <Card
                      className="flex flex-col justify-center items-center w-full"
                      sx={{ mt: 6, py: 2 }}
                      style={{
                        borderRadius: '0.75rem',
                        maxWidth: 640,
                      }}
                    >
                      <Typography fontSize={20} className="flex flex-row items-center">
                        Your Ticket(s)
                        <img
                          src={ticketIcon}
                          alt="Ticket Icon"
                          style={{ width: 30, height: 30, marginLeft: '10px', marginBottom: '10px' }}
                        />
                      </Typography>
                      <Box
                        sx={{
                          maxHeight: 300,
                          overflowY: 'auto',
                          width: '98%',
                          px: isLgScreen ? '12%' : isMdScreen ? '5%' : isSgScreen ? '8%' : '2%',
                          pb: 2,
                          ...(isMdScreen && {
                            '&::-webkit-scrollbar': {
                              width: 5,
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#888',
                              borderRadius: 10,
                            },
                            '&::-webkit-scrollbar-track': {
                              backgroundColor: '#f2f2f2',
                            },
                          }),
                        }}
                      >
                        {userTickets?.tickets.map((ticket, index) => (
                          <Card
                            key={index}
                            className="flex justify-center items-center"
                            sx={{
                              mt: 3,
                              height: 60,
                              bgcolor: '#D9D9D91A',
                              width: '100%',
                            }}
                            style={{
                              borderRadius: '0.75rem',
                            }}
                          >
                            <Typography fontSize={isSgScreen ? 22 : 20}>{ticket}</Typography>
                          </Card>
                        ))}
                      </Box>
                    </Card>
                  </>
                )}
              </>
            ) : (
              <>
                {loadingData ? (
                  <CustomSkeleton
                    sx={{ height: !isRaffle ? 160 : 240, width: '100%', mt: 4 }}
                    animation="wave"
                    variant="rounded"
                    spaceBetween={70}
                  />
                ) : (
                  <>
                    {!isRaffle ? (
                      <>
                        <Card
                          className="mt-4 py-6 px-6 items-center"
                          style={{
                            borderRadius: '0.75rem',
                          }}
                          sx={{
                            minHeight: isAccepted && acceptedOrders.length !== 0 ? 220 : 160,
                          }}
                        >
                          <div
                            className="pt-4"
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              gap: '1rem',
                            }}
                          >
                            <Grid container spacing={2} justify="center">
                              <Grid item xs={12}>
                                <Typography fontWeight={300} fontSize={20}>
                                  {t('Highest bid')}
                                </Typography>
                                <Typography fontWeight={500} fontSize={24} mt={2}>
                                  {converter?.currency?.abbr_name} {acceptedOrderList?.length > 0 ? highestBid : 'None'}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} justify="center">
                              <Grid item xs={12}>
                                <Typography fontWeight={300} fontSize={20}>
                                  {t('Total bids')}
                                </Typography>
                                <Typography fontWeight={500} fontSize={24} mt={2}>
                                  {totalBids}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                          {isAccepted && acceptedOrders.length !== 0 && (
                            <Typography align="center" mt={4}>
                              {t('Go to the chart below to accept bid')}
                            </Typography>
                          )}
                        </Card>
                      </>
                    ) : (
                      <>
                        <Card
                          className="mt-4 px-6 items-center"
                          style={{
                            borderRadius: '0.75rem',
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '1rem',
                          }}
                          sx={{ minHeight: 240 }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography fontWeight={300} fontSize={20}>
                                {t('Price per ticket')}
                              </Typography>
                              <Typography fontWeight={500} fontSize={24}>
                                {converter?.currency?.abbr_name} {formatNumberWithTwoDecimals(converter?.price)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                              <Typography fontWeight={300} fontSize={20}>
                                {t('Available tickets')}
                              </Typography>
                              <Typography fontWeight={500} fontSize={24}>
                                {productInfo?.amount} <span className="font-light">{t('out of')}</span>{' '}
                                {productInfo?.total_amount}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography fontWeight={300} fontSize={20}>
                                {t('Total tickets sold')}
                              </Typography>
                              <Typography fontWeight={500} fontSize={24}>
                                {totalTicketsSold}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                              <Typography fontWeight={300} fontSize={20}>
                                {t('Your earnings')}
                              </Typography>
                              <Typography fontWeight={500} fontSize={24}>
                                {converter?.currency?.abbr_name}{' '}
                                {formatNumberWithTwoDecimals(converter?.price * totalTicketsSold)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      </>
                    )}
                    {isWinner && (
                      <>
                        <Card
                          className="mt-4 py-2 px-6 flex flex-col justify-evenly items-center xl:px-10"
                          style={{
                            borderRadius: '0.75rem',
                          }}
                          sx={{ minHeight: 240 }}
                        >
                          {customer ? (
                            <>
                              <div className="flex w-full justify-between items-start">
                                <div className="flex flex-col justify-between  w-1/2">
                                  <Typography fontWeight={300} fontSize={20}>
                                    {isRaffle ? 'Winner Ticket' : 'Bid accepted'}
                                  </Typography>
                                  <Typography
                                    fontWeight={500}
                                    fontSize={24}
                                    style={{
                                      wordWrap: 'break-word',
                                      whiteSpace: 'break-spaces',
                                    }}
                                  >
                                    {isRaffle ? (
                                      <>{winnerUserTicketNumber}</>
                                    ) : (
                                      <>
                                        {converter?.currency?.abbr_name} {customer?.price}
                                      </>
                                    )}
                                  </Typography>
                                </div>
                                <div className="flex flex-col justify-between  w-1/2 ml-2">
                                  <Typography fontWeight={300} fontSize={20}>
                                    {isRaffle ? 'Ticket Owner' : 'From'}
                                  </Typography>
                                  <Typography
                                    fontWeight={500}
                                    fontSize={24}
                                    style={{
                                      wordWrap: 'break-word',
                                      whiteSpace: 'break-spaces',
                                    }}
                                  >
                                    {isRaffle ? (
                                      <>
                                        {customer?.user?.firstName} {customer?.user?.lastName}
                                      </>
                                    ) : (
                                      <>
                                        {customer?.user?.firstName} {customer?.user?.lastName}
                                      </>
                                    )}
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex flex-row justify-center w-full">
                                {productData?.internationalShipment && !productData?.rates && isUnfulfilled && (
                                  <CustomButton
                                    style={{ widht: '90%', maxWidth: '50%' }}
                                    onClick={() => setIsShowCustomsDeclaration(true)}
                                  >
                                    {t('add customs declaration')}
                                  </CustomButton>
                                )}
                                {productData?.rates && !productData?.rates?.length && isUnfulfilled && isRaffle && (
                                  <Typography sx={{ marginTop: 6 }} align="center" fontSize={20}>
                                    {t(
                                      'Looks like there are no available options for your shipment. Please contact support using button in the right bottom corner or submit a dispute to involve Support manager in manual delivery.'
                                    )}
                                  </Typography>
                                )}
                                {productData?.rates && !delivery?.trackingNumber && isUnfulfilled && isRaffle && (
                                  <CustomButton
                                    disabled={!productData?.rates?.length}
                                    style={{
                                      widht: '90%',
                                      maxWidth: '50%',
                                    }}
                                    onClick={() => {
                                      setIsFulfilmentForm(true);
                                      setIsShowFulfilledItem(true);
                                    }}
                                  >
                                    {t('confirm shipment')}
                                  </CustomButton>
                                )}
                                {(isFulfilled || isUnfulfilled || isPaid || isDisputed) && (
                                  <CustomButton
                                    sx={{ ml: isUnfulfilled ? 2 : 0 }}
                                    style={{
                                      widht: isUnfulfilled ? '90%' : '100%',
                                      maxWidth: isUnfulfilled ? '50%' : '70%',
                                    }}
                                    onClick={() => {
                                      setIsOpenDisputeForm(true);
                                    }}
                                    disabled={isDisputed || isPaid}
                                  >
                                    {t(`${isDisputed ? 'opened' : 'open'} dispute`)}
                                  </CustomButton>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <Typography fontSize={20}>{t('Raffle ended without a winner')}</Typography>
                            </>
                          )}
                        </Card>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {productData?.user?._id === user?._id && (
              <>
                {!isUnfulfilled && !isFulfilled && !isDisputed && !isPay && isRaffle && (
                  <>
                    <div className="flex flex-col items-center">
                      <Typography className="text-center" sx={{ mt: 4 }} fontSize={20}>
                        {t('End the raffle and draw a winner by clicking the button below!')}
                      </Typography>
                      <CustomButton
                        onClick={() => {
                          setOpenEndRaffleForm(true);
                        }}
                        sx={{ mt: 4 }}
                        disabled={!isAccepted || tickets?.length === 0}
                      >
                        {t('end raffle')}
                      </CustomButton>
                    </div>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <div className="px-4 md:px-6 lg:px-10 xl:px-12">
          {loadingData ? (
            <>
              {!isRaffle && (
                <CustomSkeleton sx={{ height: 200, width: '100%', mt: 10, px: 2 }} animation="wave" variant="rounded" />
              )}
            </>
          ) : (
            <>
              {!isRaffle && (
                <div className="mt-4">
                  {isWinner && productData?.user?._id === user?._id && (
                    <Typography className="text-center" mt={4} fontSize={20}>
                      {t(
                        'The Auction has ended, but you can still check out all the bids from your auction in the chart below:'
                      )}
                    </Typography>
                  )}
                  <Grid container spacing={2} className="py-16 max-[435px]:pl-2 min-[435px]:px-4">
                    <Grid item xs={isMdScreen && isObserverAndWinner ? 6 : 12}>
                      <BidsTable productData={productData} user={user} acceptedOrderList={acceptedOrderList} />
                    </Grid>
                    <Grid item xs={isMdScreen && isObserverAndWinner ? 6 : 12}>
                      {isObserverAndWinner && (
                        <WinnerUserCard
                          {...{
                            customer,
                            isRaffle,
                            acceptedOrderList,
                            highestBid,
                            winnerUserTicketNumber,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}
            </>
          )}
          {productData?.user?._id === user?._id && (
            <>
              {isAccepted && !isUnfulfilled && (
                <>
                  <div className="flex flex-col items-center">
                    {!isRaffle && (
                      <>
                        {loadingData ? (
                          <CustomSkeleton sx={{ height: 50, width: 300, mt: 4 }} animation="wave" variant="rounded" />
                        ) : (
                          <>
                            <CustomButton
                              onClick={() => {
                                setOpenEndAuctionForm(true);
                              }}
                              variant="contained"
                              sx={{ width: 180, mt: 4 }}
                              disabled={!isAccepted || acceptedOrders.length === 0}
                            >
                              <div style={{ flex: 1 }}>{t('accept the highest bid')}</div>
                            </CustomButton>
                          </>
                        )}
                      </>
                    )}
                    <Typography
                      className="text-center"
                      sx={{ mt: 4 }}
                      fontSize={20}
                      width={isMdScreen ? '70%' : '100%'}
                    >
                      {loadingData ? (
                        <CustomSkeleton style={{ width: '100%' }} />
                      ) : (
                        <>
                          {t(
                            `Are you not satisfied with the ${
                              isRaffle ? 'result' : 'bids'
                            } or do you no longer want to run this ${isRaffle ? 'raffle' : 'auction'}? End your ${
                              isRaffle ? 'raffle' : 'auction'
                            } by clicking the button below.`
                          )}
                        </>
                      )}
                    </Typography>
                    {loadingData ? (
                      <CustomSkeleton sx={{ height: 50, width: 300, mt: 4 }} animation="wave" variant="rounded" />
                    ) : (
                      <>
                        <CustomButton
                          onClick={() => {
                            setIsAuctionOrRaffleCanceled(true);
                          }}
                          sx={{ mt: 4 }}
                        >
                          {t(`cancel ${isRaffle ? 'raffle' : 'auction'}`)}
                        </CustomButton>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {isRaffle && user?.role && recentlyViewedProductData !== null && productData?.user?._id !== user?._id && (
          <>
            <div className="mt-8 px-4">
              <Divider />
              <Typography className="text-center" fontSize={24} marginTop={4} marginBottom={4}>
                {t('Recently viewed Raffles')}
              </Typography>
              <SwiperComponent
                data={recentlyViewedProductData}
                isLoading={isLoadingRecentlyViewedData}
                leftCenteredSlides={true}
                sliderWidth={isSgMaxScreen ? 250 : 300}
                sliderOffset={8}
              />
            </div>
          </>
        )}
      </div>
      <div>
        <PaymentForm
          open={openPaymentForm}
          onClose={handleClosePaymentForm}
          productData={productData}
          paymentType={paymentType}
          order={buyNowOrder}
          isOpenSuccessForm={openSuccessForm}
          isCloseForm={() => {
            setOpenSuccessForm(false);
          }}
          highestBid={highestBid}
        />
      </div>
      {(openEndRaffleForm || openEndAuctionForm) && (
        <>
          <FormTemplate
            open={openEndRaffleForm || openEndAuctionForm}
            onClose={() => {
              if (isRaffle) {
                setOpenEndRaffleForm(false);
                setIsConfirmEndRaffle(false);
              } else {
                setOpenEndAuctionForm(false);
                setIsConfirmEndRAuction(false);
              }
            }}
            title={
              <ProductAuctionRaffleDialogTexts
                {...{
                  isRaffle,
                  isPay,
                  isConfirmEndRaffle,
                  isConfirmEndAuction,
                  t,
                }}
              />
            }
            dialogContent={
              isConfirmEndRaffle || isConfirmEndAuction ? (
                <>
                  {loadingComfirmEndAuctionOrRaffleBnt ? (
                    <>
                      <div className="my-20 flex justify-center items-center">
                        <CircularProgress color="inherit" />
                      </div>
                    </>
                  ) : (
                    <>
                      {isPay && !customer ? (
                        <>
                          <Typography fontSize={20} className="text-center">
                            {t(
                              'There is no winner due to no one of participants have answered the skill question correctly.You still get the payment that will be available soon.'
                            )}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography className="text-center" fontWeight={300} fontSize={22}>
                            {isRaffle ? 'The winner of your raffle is:' : 'You have accepted the bid on:'}
                          </Typography>
                          <div className="mt-4">
                            <Typography className="text-center" fontWeight={300} fontSize={20}>
                              {isRaffle ? 'Ticket number' : 'Bid'}
                            </Typography>
                            <CardComponent
                              value={
                                !isRaffle
                                  ? acceptedOrderList?.[0]?.converter?.currency?.abbr_name + ' ' + winnerNumberValue
                                  : winnerNumberValue
                              }
                            />
                          </div>
                          <div className="mt-4">
                            <Typography className="text-center" fontWeight={300} fontSize={20}>
                              {isRaffle ? 'Ticket Owner' : 'from'}
                            </Typography>
                            <CardComponent value={`${winnerUserNumber.firstName} ${winnerUserNumber.lastName}`} />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <Typography className="text-center" mt={4} fontWeight={300} fontSize={20}>
                    {t(
                      `You can go to your profile page to view the fulfilment and your next payout from this ${
                        isRaffle ? 'raffle' : 'auction'
                      }.`
                    )}
                  </Typography>
                </>
              ) : (
                !isRaffle &&
                !isConfirmEndAuction && (
                  <>
                    <InputWithCurrency
                      sx={{ pt: 0.1 }}
                      value={highestBid}
                      currencyAbrName={acceptedOrderList?.[0]?.converter?.currency?.abbr_name}
                      isReadOnly={true}
                    />
                    <Typography mt={2} className="text-center" fontSize={24}>
                      {t('From')}:
                    </Typography>
                    <CardComponent
                      value={`${acceptedOrderList?.[0]?.user?.firstName} ${acceptedOrderList?.[0]?.user?.lastName}`}
                    />
                  </>
                )
              )
            }
            dialogActions={
              <>
                {isConfirmEndRaffle || isConfirmEndAuction ? (
                  <>
                    <CustomButton sx={{ mt: 2 }} variant="contained" onClick={() => navigate('/profile/0')}>
                      {t('go to profile page')}
                    </CustomButton>
                  </>
                ) : (
                  <CustomButton
                    onClick={handleComfirmEndAuctionOrRaffle}
                    variant="contained"
                    disabled={!isAccepted || loadingComfirmEndAuctionOrRaffleBnt}
                  >
                    <LoadingIndicator
                      isLoading={loadingComfirmEndAuctionOrRaffleBnt}
                      value={
                        <>
                          <div style={{ flex: 1 }}>{isRaffle ? 'end raffle' : 'accept bid'}</div>
                        </>
                      }
                    />
                  </CustomButton>
                )}
              </>
            }
          />
        </>
      )}
      {isAuctionOrRaffleCanceled && (
        <FormTemplate
          open={true}
          onClose={() => {
            setIsAuctionOrRaffleCanceled(false);
          }}
          title={`You are about to cancel this ${
            isRaffle ? 'raffle' : 'auction'
          }. We will return money to all participants, click the button below to proceed.`}
          dialogActions={
            <>
              <CustomButton
                onClick={handleCancelAuctionOrRaffle}
                variant="contained"
                disabled={loadingCancelActionOrRaffleBtn}
              >
                <LoadingIndicator
                  isLoading={loadingCancelActionOrRaffleBtn}
                  value={t(`cancel ${isRaffle ? 'raffle' : 'auction'}`)}
                />
              </CustomButton>
            </>
          }
        />
      )}
      {isConfirmReceivalForm && (
        <FormTemplate
          open={isConfirmReceivalForm}
          onClose={() => {
            setIsConfirmReceivalForm(false);
          }}
          title={t('Confirm the item receival')}
          subtitle={t('You are about to confirm that item was received and approve the payment for seller.')}
          dialogActions={
            <>
              <CustomButton
                style={{
                  widht: '90%',
                  maxWidth: '50%',
                }}
                disabled={loadingConfirmReceivalBtn}
                onClick={handleConfirmReceival}
              >
                <LoadingIndicator isLoading={loadingConfirmReceivalBtn} value={t('confirm!')} />
              </CustomButton>
            </>
          }
        />
      )}
      {isOpenDisputeForm && (
        <FormTemplate
          open={isOpenDisputeForm}
          onClose={() => {
            setIsOpenDisputeForm(false);
            setDisputeMessageError('');
          }}
          title="Dispute message"
          dialogContent={
            <>
              <CustomInputs
                value={disputeMessage}
                placeholder={t('Dispute message')}
                name={t('Dispute message')}
                variant="outlined"
                required
                type="text"
                sx={{ mt: 1 }}
                onChange={(e) => {
                  setDisputeMessage(e.target.value);
                  setDisputeMessageError('');
                }}
                helperText={disputeMessageError ? disputeMessageError : ' '}
                error={!!disputeMessageError}
              />
            </>
          }
          dialogActions={
            <>
              <CustomButton onClick={handleOpenDispute} variant="contained" disabled={loadingOpenDisputeBtn}>
                <LoadingIndicator isLoading={loadingOpenDisputeBtn} value={t('Send message')} />
              </CustomButton>
            </>
          }
        />
      )}
      {isShowFulfilledItem && (
        <UnfulfilledItemForm
          onClose={() => {
            setIsShowFulfilledItem(false);
            setIsFulfilmentForm(false);
          }}
          productItem={productData}
          orderItem={productData?.order}
          onSuccess={() => {
            showSnackbar(null, 'Email sent! Don’t forget to check the spam folder!', 'success');
            fetchData();
            setIsShowFulfilledItem(false);
            setIsFulfilmentForm(false);
          }}
          isFulfilment={isFulfilmentForm}
        />
      )}
      {isShowCustomsDeclaration && (
        <CustomsDeclarationForm
          onClose={() => setIsShowCustomsDeclaration(false)}
          productItem={productData}
          onSuccess={() => {
            showSnackbar(null, 'Success', 'success');
            fetchData();
            setIsShowCustomsDeclaration(false);
          }}
        />
      )}
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default ProductComponent;

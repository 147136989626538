import React from 'react';
const accordionsData = [
  {
    title: 'How do I create an auction or raffle?',
    content:
      'After signing up or logging in click on either the menu icon on the left or the profile icon on the right. In the menu you have the option to click on “Create raffle or auction” from there you will be redirected to the create an auction or raffle page, where you can list your item for auction or raffle.',
  },
  {
    title: 'What are the fees for creating an auction or raffle?',
    content:
      "Creating an auction or a raffle with us is absolutely free! But remember, nothing in life is truly free. So, when your auction item sells or those raffle tickets fly off the shelves, we'll take a modest 5%. For auctions, we take 5% of the winning bid, and for raffles, we get 5% of each ticket sold. It's our way of being part of the celebration, without actually throwing confetti!",
  },
  {
    title: 'Are Raffles in Outbidda a lottery?',
    content:
      "Nope, this isn't your run-of-the-mill lottery. It's a full-blown competition and falls within prize competitions or free draws. Here, the winners are decided by a skill-based game, not just luck. So, get your game face on!",
  },
  {
    title: 'What is a skill-based game?',
    content:
      "A game that focuses on skill is designed to evaluate a player's expertise or knowledge in a particular area. Participants who provide accurate responses in this skill-focused game will be eligible for the final selection process.",
  },
  {
    title: 'How is the winner drawn from a Raffle?',
    content:
      "Outbidda uses its own random draw generator. It's like a digital Fort Knox for raffles, ensuring every participant's experience is as safe and fair as a game of tic-tac-toe with your grandma. Scammers, beware!",
  },
  {
    title: 'Can I withdraw from a bid or raffle ticket purchase?',
    content: (
      <>
        Well, think of your bid or raffle ticket like a toothpaste squeezed out of the tube - there's no getting it back
        in!. We recommend all bidders and ticket buyers channel their inner chess grandmaster - ponder, strategize, and
        then make your move. Remember, in the game of bids and raffles, it's always wise to think twice before you roll
        the dice!
        <div style={{ fontStyle: 'italic' }}>
          * Note: For auctions only, if you loose an auction the amount will be refunded back to you. We only hold the
          money in your bank account until a higher bid then yours has been placed in the auction.
        </div>
      </>
    ),
  },
  {
    title: 'Who can participate?',
    content:
      'For auctions, anybody who has created an account can participate, but raffles the entrants must be over the age of 18 years. ',
  },
  {
    title: 'How will I know if I won an auction or raffle?',
    content:
      'Winners are notified via email immediately after an auction ends or a raffle is drawn. You can also check the status of auctions and raffles you participated in your Outbidda profile.',
  },
  {
    title: 'How can I bid on an auction?',
    content:
      "To bid on an auction, first ensure you are logged in. Go to the auction page for the item you're interested in and enter your bid amount. If your bid is the highest when the auction ends, you will be declared the winner. ",
  },
  {
    title: 'Is it safe to make payments through your site?',
    content:
      "Yes, all payments are processed through Stripe, a renowned third-party payment provider known for its stringent security measures and compliance with payment processing regulations. By leveraging Stripe's secure platform, we ensure that your payment details are handled with the utmost care and security.",
  },
  {
    title: 'What happens if an item received is not as described?',
    content:
      'We have a dispute resolution process for such situations. Please report the issue to our support team, support@outbidda.com, within a specified timeframe, and our team will assist in resolving the issue.',
  },
  {
    title: 'How can I delete my data?',
    content:
      'You can leave an account deletion inquiry to our support team by leaving a message with "Delete my data" subject to support@outbidda.com email. You will receive success confirmation during the next 48 hours.',
  },
];

export default accordionsData;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import { formatNumberWithTwoDecimals } from '../../utils/functions/formatNumberWithTwoDecimals';
import { baseURLImage } from '../../utils/constants';
import { Card, CardMedia, Typography, Skeleton } from '@mui/material';
import moment from 'moment';

function DateDifference({ item, t }) {
  if (!item) {
    return null;
  }

  const endDate = moment(item);
  const currentDate = moment();
  const daysDifference = endDate.diff(currentDate, 'days');

  let message;

  if (daysDifference < 0) {
    message = 'Ended';
  } else if (daysDifference < 1) {
    message = 'Less than 1 day';
  } else {
    message = daysDifference + ' ' + t('days');
  }

  return (
    <>
      <span className="font-light"> {message} </span>
    </>
  );
}

const ProductItemComponent = ({ itemData, isRaffles, itemIndex, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSgMaxScreen, isSgScreen, isMdScreen, isLgScreen, isXlScreen } = useMediaQueries();

  const handleProductRedirect = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div
      className="flex flex-col items-center px-2 md:px-3"
      style={{ width: isSgMaxScreen ? '80%' : '50%', minWidth: isSgMaxScreen ? '80%' : '50%' }}
    >
      <div className="mt-2 flex items-center justify-between w-full" style={{ borderBottom: '1px solid #d4d4d4' }}>
        <Typography
          variant="subtitle1"
          width={isLgScreen || isXlScreen ? '60%' : isSgMaxScreen ? '80%' : '70%'}
          fontWeight="300"
          fontSize={14}
        >
          {t('RANK')}
        </Typography>
        {(isLgScreen || isXlScreen || ((isSgMaxScreen || isMdScreen || isSgScreen) && !isRaffles)) && (
          <Typography
            className="flex justify-end"
            variant="subtitle1"
            style={{ whiteSpace: isMdScreen ? 'nowrap' : 'wrap', textAlign: 'right' }}
            fontWeight="300"
            fontSize={14}
            width={isLgScreen || isXlScreen || isSgMaxScreen ? '20%' : '30%'}
          >
            {isRaffles ? `${t('EXPIRE DATE')}` : `${t('LAST BID')}`}
          </Typography>
        )}
        {(isLgScreen || isXlScreen || ((isSgMaxScreen || isMdScreen || isSgScreen) && isRaffles)) && (
          <Typography
            className="flex justify-end"
            variant="subtitle1"
            style={{ whiteSpace: isMdScreen ? 'nowrap' : 'wrap', textAlign: 'right' }}
            fontWeight="300"
            fontSize={14}
            width={isLgScreen || isXlScreen || isSgMaxScreen ? '20%' : '30%'}
          >
            {isRaffles ? `${t('TICKET PRICE')}` : `${t('BUY IT NOW')}`}
          </Typography>
        )}
      </div>
      <div className="w-full overflow-x-auto">
        {itemData.map((item, index) => (
          <div
            key={index}
            className="top-table-item mt-4 mb-4 px-1 py-2 flex flex-row items-center justify-between"
            style={{ cursor: 'pointer' }}
            onClick={() => handleProductRedirect(item?._id)}
          >
            <div
              className="flex items-center"
              style={{ width: isLgScreen || isXlScreen ? '60%' : isSgMaxScreen ? '80%' : '70%' }}
            >
              <Typography
                fontWeight="600"
                fontSize={isMdScreen ? 24 : 16}
                width={isSgMaxScreen ? 10 : 20}
                pr={isSgMaxScreen ? 2 : 3}
              >
                {itemIndex ? itemIndex + index : index + 1}
              </Typography>
              <Card
                className="ml-2"
                sx={{
                  minWidth: isLgScreen ? 100 : isMdScreen ? 70 : 54,
                  borderRadius: '10px',
                }}
              >
                {isLoading ? (
                  <Skeleton
                    sx={{ height: isLgScreen ? 100 : isMdScreen ? 70 : 54 }}
                    animation="wave"
                    variant="rectangular"
                  />
                ) : (
                  <div className="flex items-center justify-center w-full">
                    <>
                      <CardMedia
                        className="justify-center items-center"
                        sx={{
                          height: isLgScreen ? 100 : isMdScreen ? 70 : 54,
                          width: isLgScreen ? 100 : isMdScreen ? 70 : 54,
                          cursor: 'pointer',
                          display: 'flex',
                        }}
                        image={item?.media[0] ? baseURLImage + item.media[0] : ''}
                        title={item?.media[0] ? `image${index + 1}` : 'No Image Available'}
                      >
                        {!item?.media[0] && (
                          <Typography align="center" fontSize={16} px={1}>
                            {t('No image')}
                          </Typography>
                        )}
                      </CardMedia>
                    </>
                  </div>
                )}
              </Card>
              <div
                className="flex flex-col justify-between"
                style={{
                  whiteSpace: isMdScreen ? 'wrap' : 'nowrap',
                  height: isLgScreen ? 95 : isMdScreen ? 65 : 49,
                  padding: isSgMaxScreen || isMdScreen || isSgScreen ? '6px 0 4px' : '10px 0 8px',
                  marginLeft: 12,
                }}
              >
                <Typography
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                    WebkitLineClamp: isLgScreen ? 2 : isMdScreen || isSgScreen || isSgMaxScreen ? 1 : 3,
                    WebkitBoxOrient: 'vertical',
                    textWrap: 'wrap',
                    display: '-webkit-box',
                  }}
                  fontWeight={400}
                >
                  {isLoading ? (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      height={10}
                      sx={{
                        fontSize: '20rem',
                      }}
                    />
                  ) : (
                    <>{item?.title}</>
                  )}
                </Typography>
                <Typography
                  fontWeight={200}
                  fontSize={isMdScreen ? 16 : 12}
                  lineHeight={1.2}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    textWrap: 'wrap',
                    display: '-webkit-box',
                  }}
                >
                  {isLoading ? (
                    <Skeleton
                      animation="wave"
                      height={10}
                      variant="text"
                      sx={{
                        fontSize: '20rem',
                      }}
                      style={{ marginBottom: 8 }}
                    />
                  ) : (
                    <>
                      {`${t('from')}:`} {item?.country?.name}
                    </>
                  )}
                </Typography>
              </div>
            </div>
            {(isLgScreen || isXlScreen || ((isSgMaxScreen || isMdScreen || isSgScreen) && !isRaffles)) && (
              <Typography
                className="flex flex-col justify-center items-end md:flex-row md:justify-end md:items-center"
                width={isLgScreen || isXlScreen || isSgMaxScreen ? '20%' : '30%'}
                fontSize={isMdScreen ? 16 : 14}
                style={{ whiteSpace: isMdScreen ? 'nowrap' : 'wrap' }}
              >
                {isLoading ? (
                  <Skeleton animation="wave" height={10} width="80%" variant="text" />
                ) : (
                  <>
                    {isRaffles ? (
                      <>
                        {/* time */}
                        <DateDifference item={item?.end} t={t} />
                      </>
                    ) : (
                      <>
                        <span className="font-light mr-1">{item?.converter?.currency?.abbr_name} </span>
                        {/* last bid */}
                        {item?.orderList?.[0]?.converter?.price ? (
                          <>{formatNumberWithTwoDecimals(item?.orderList?.[0]?.converter?.price)}</>
                        ) : (
                          'None'
                        )}
                      </>
                    )}
                  </>
                )}
              </Typography>
            )}
            {(isLgScreen || isXlScreen || ((isSgMaxScreen || isMdScreen || isSgScreen) && isRaffles)) && (
              <Typography
                className="flex flex-col justify-center items-end md:flex-row md:justify-end md:items-center"
                style={{
                  whiteSpace: isMdScreen ? 'nowrap' : 'wrap',
                }}
                fontSize={isMdScreen ? 16 : 14}
                width={isLgScreen || isXlScreen || isSgMaxScreen ? '20%' : '30%'}
              >
                {isLoading ? (
                  <Skeleton animation="wave" height={10} width="80%" variant="text" />
                ) : (
                  <>
                    {(!isRaffles ? formatNumberWithTwoDecimals(item?.converter?.buy_now) : true) !== 0 && (
                      <span className="mr-1 font-light">{`${item?.converter?.currency?.abbr_name} `}</span>
                    )}
                    {isRaffles ? (
                      <span>{formatNumberWithTwoDecimals(item?.converter?.price)}</span>
                    ) : (
                      <span>
                        {formatNumberWithTwoDecimals(item?.converter?.buy_now) !== 0
                          ? formatNumberWithTwoDecimals(item?.converter?.buy_now)
                          : 'Off'}
                      </span>
                    )}
                  </>
                )}
              </Typography>
            )}
          </div>
        ))}
        {itemData.length === 0 && (
          <>
            <Typography align="center" mt={4}>
              {t('No items')}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductItemComponent;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';
import { CustomButton } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';

function NotFoundPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <FormTemplate
        open={true}
        onClose={() => {
          navigate('/');
        }}
        title={'404 - Not Found'}
        subtitle={'The page you are looking for does not exist.'}
        dialogActions={
          <>
            <CustomButton variant="contained" onClick={() => navigate('/')}>
              {t('go to home page')}
            </CustomButton>
          </>
        }
      />
    </>
  );
}
export default NotFoundPage;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQueries } from './js/useMediaQueries';
import TextField from '@mui/material/TextField';
import {
  CustomInputs,
  CustomAutocomplete,
} from '../../common/styles/customStyledComponents/customStyledComponents.jsx';

const AddressFields = ({ values, onChange, errors, countriesList }) => {
  const { t } = useTranslation();
  const { isSgMaxScreen } = useMediaQueries();
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    if (countriesList?.length > 0) {
      const selected = values.country ? countriesList?.find((country) => country?.iso === values.country) : null;
      setSelectedCountry(selected);
    }
  }, [countriesList, values.country]);
  return (
    <>
      <div className="mt-4 flex flex-row justify-between max-[435px]:flex-col max-[435px]:items-center">
        <CustomAutocomplete
          value={selectedCountry}
          onChange={(e, newValue) => onChange('country', newValue ? newValue.iso : '')}
          options={countriesList}
          getOptionLabel={(countriesList) => countriesList?.name}
          style={{ width: isSgMaxScreen ? '100%' : '49%' }}
          loading
          renderInput={(params) => (
            <TextField
              {...params}
              name={t('Country')}
              label={t('Country')}
              InputProps={{
                ...params.InputProps,
              }}
              helperText={errors.country ? errors.country : ' '}
              error={!!errors.country}
            />
          )}
        />
        <CustomInputs
          placeholder={t('Zip Code')}
          variant="outlined"
          value={values.postalCode}
          required
          style={{ width: isSgMaxScreen ? '100%' : '49%' }}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => onChange('postalCode', e.target.value)}
          helperText={errors.postalCode ? errors.postalCode : ' '}
          error={!!errors.postalCode}
        />
      </div>
      <div className="flex flex-row justify-between max-[435px]:flex-col max-[435px]:items-center">
        <CustomInputs
          placeholder={t('City')}
          variant="outlined"
          value={values.city}
          required
          style={{ width: isSgMaxScreen ? '100%' : '49%' }}
          onChange={(e) => onChange('city', e.target.value)}
          helperText={errors.city ? errors.city : ' '}
          error={!!errors.city}
        />
        <CustomInputs
          placeholder={t('State')}
          variant="outlined"
          value={values.state}
          style={{ width: isSgMaxScreen ? '100%' : '49%' }}
          type="string"
          onWheel={(e) => e.target.blur()}
          onChange={(e) => onChange('state', e.target.value)}
          helperText={errors.state ? errors.state : ' '}
        />
      </div>
      <div className="flex flex-row justify-between max-[435px]:flex-col max-[435px]:items-center">
        <CustomInputs
          placeholder={t('Street Address')}
          variant="outlined"
          value={values.street}
          required
          style={{ width: '100%' }}
          onChange={(e) => onChange('street', e.target.value)}
          helperText={errors.street ? errors.street : ' '}
          error={!!errors.street}
        />
      </div>
    </>
  );
};

export default AddressFields;

import React from 'react';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';

const StyledSkeleton = styled(Skeleton)({
  animation: 'wave',
  height: '15px',
  width: '60%',
  variant: 'text',
  marginTop: 10,
  marginBottom: 4,
});

const CustomSkeleton = (props) => {
  return <StyledSkeleton {...props} />;
};

export { CustomSkeleton };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Divider } from '@mui/material';
import { LINKS } from '../utils/constants';

function BannedPage() {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-center px-4 py-8 min-[435px]:py-10 min-[435px]:px-6 sm:p-10 lg:p-20 xl:mx-20">
        <div>
          <Typography fontWeight={500} fontSize={24} ml={2} align="center">
            {t(
              `Sorry, but you've been banned from our site for violating our rules. If you believe this is a mistake, please contact our support team at ${LINKS.EMAIL}. Have a great day!`
            )}
          </Typography>
        </div>
        <Divider />
      </div>
    </>
  );
}

export default BannedPage;

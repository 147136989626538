import { createSlice } from '@reduxjs/toolkit';

const userAccountSellerSlice = createSlice({
  name: 'userAccountSeller',
  initialState: {
    isUserAccountSeller: false,
    selectedCurrentTab: 0,
  },
  reducers: {
    setIsUserAccountSeller: (state, action) => {
      state.isUserAccountSeller = action.payload;
    },
    setSelectedCurrentTab: (state, action) => {
      state.selectedCurrentTab = action.payload;
    },
  },
});

export const { setIsUserAccountSeller } = userAccountSellerSlice.actions;
export const { setSelectedCurrentTab } = userAccountSellerSlice.actions;

export const selectIsUserAccountSeller = (state) => state.userAccountSeller;

export default userAccountSellerSlice.reducer;

export const LINKS = {
  INSTAGRAM: 'https://www.instagram.com/outbidda?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
  FACEBOOK: 'https://www.facebook.com/',
  TWITTER: 'https://twitter.com/',
  EMAIL: 'support@outbidda.com',
};

export const baseURLImage =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://api.outbidda.com'
    : 'https://outbidda-backend.onrender.com';

export const baseURL = baseURLImage + '/v1/';

export const countriesBanList = {
  Russia: ['Russia', 'RU', 'RUS'],
  Belarus: ['Belarus', 'BY', 'BLR'],
  'North Korea': ['North Korea', 'KP', 'PRK'],
  Iran: ['Iran', 'IR', 'IRN'],
  Eritrea: ['Eritrea', 'ER', 'ERI'],
};

export const supportUserId =
  process.env.REACT_APP_NODE_ENV === 'production' ? '66071d15a554169e81a37240' : '65d6b3c3208951485ef3ec0a';

import profileIcon from '../icons/profileIcon.svg';
import myAuctionsAndRafflesIcon from '../icons/myAuctionsAndRafflesIcon.svg';
import watchlistIcon from '../icons/watchlistIcon.svg';
import settingsIcon from '../icons/settingsIcon.svg';
import createAuctionIcon from '../icons/createAuctionIcon.svg';
import ticketIcon from '../icons/ticketIcon.svg';
import messagesIcon from '../icons/messagesIcon.svg';

export const menuItems = [
  { to: '/profile/0', label: 'Profile', icon: profileIcon },
  {
    to: '/create-raffle-or-auction/0',
    label: 'Create Raffle',
    icon: ticketIcon,
  },
  {
    to: '/create-raffle-or-auction/1',
    label: 'Create Auction',
    icon: createAuctionIcon,
  },
  {
    to: '/profile/1',
    label: 'My Auctions and Raffles',
    icon: myAuctionsAndRafflesIcon,
  },
  { to: '/chat', label: 'Messages', icon: messagesIcon, additionally: true },
  { to: '/profile/2', label: 'Watchlist', icon: watchlistIcon },
  { to: '/profile/5', label: 'Settings', icon: settingsIcon },
];

export const privacyPolicyText = [
  {
    title: 'Privacy Policy and Data Protection',
    content: [
      'We Highly Value Our Customers and Respect Your Privacy. We take your privacy seriously and handle any information we collect about you with the utmost care and security. Your interaction with our website and utilization of our services or features are governed by our terms and conditions, which include this privacy policy. This policy outlines how we process any personal data collected from you or provided by you. We kindly ask you to carefully review the following information to understand our principles and practices concerning your personal data and how we intend to handle it. By using our services, you acknowledge and consent to the processing of your personal data in accordance with this Privacy Notice. This processing is necessary, among other reasons, to fulfill contractual obligations, facilitate communication, and send newsletters.',
      'For privacy queries, you can contact us at support@outbidda.com. Other contact details are on our website.',
    ],
  },
  {
    title: 'Handling Your Personal Data',
    content: [
      'At Outbidda, We Aspire to Be a Trustworthy and Responsible Online Marketplace. Our goal at Outbidda is to provide a dependable and responsible online marketplace where you can discover unique items that resonate with your interests. In general, we collect and utilize your personal data to effectively operate our business. This entails making our online marketplace accessible to you, maintaining its currency, ensuring its functionality, enabling sellers to offer items in auctions and raffles, and allowing users to bid on and purchase these items. We also use a portion of your personal data for analytics, such as understanding trends in various categories, and for marketing purposes. Additionally, we employ personal data to fulfill our legal obligations, including reporting to tax and other regulatory authorities.',
    ],
  },
  {
    title: 'Exploring Our Online Marketplace',
    content: [
      "The personal data we gather from our online marketplace and users provides insights into how people discover and utilize Outbidda. It helps us understand user interests, improve our products and services (such as optimizing item discoverability), and ensure the smooth operation of our online marketplace. We also compile statistics on daily and monthly visitor counts, which offer insights into our business's growth.",
      "Prior to creating an account or signing in to our online marketplace, we only collect technical information, including your IP address, device details, and access times. Once you grant us permission, often referred to as 'consent,' we may also collect electronic identification data from your browser or device using technologies like cookies. This data encompasses a cookie ID, your browsing behavior on our platform, your preferences for specific items, and mobile device identifiers. These data elements also enable us to test new features.",
    ],
  },
  {
    title: 'Utilizing Our Online Marketplace and Registering an Account',
    content: [
      "You can freely explore the special items available on Outbidda without the need for an account. However, if you wish to participate in auctions, entering raffles, or if you have an item you'd like to sell on our platform, you must create an account. Having an account also enables you to follow items and online auctions, save searches, and ensures you won't miss out on items you're interested in.",
      'Upon creating an account, you are required to agree to our Terms & Conditions, which encompass the Buyer Terms and Seller Terms. This agreement establishes a legally binding relationship between you and us, providing the legal foundation for processing the personal data in your account. This processing encompasses the following purposes:',
      [
        "To facilitate both buying and selling on Outbidda in your country, we rely on your address information. Your address is also utilized to ensure that any items you've purchased or sold in an auction or raffle can be delivered to your location or made available for pick-up at your address.",
        "Occasionally, it's essential for us to reach out to you. For this purpose, we utilize your email address and phone number. These contact details also play a role in coordinating the shipment of items you've purchased or sold through our online marketplace.",
        'When you engage in buying or selling items on Outbidda, the payment process involves both us and our third-party service provider. We collect and store transaction and payment information, which includes details such as invoices, payment amounts, payment dates, chosen payment methods, payment status, bank account information, and credit card details. This data is retained for payment processing and billing purposes. Your financial information is also utilized to facilitate payments, refunds, and ensure seamless financial transactions.',
        'We may use your contact details to offer customer support in the event of questions or issues related to a purchase or sale on our online marketplace.',
      ],
      "Outbidda may occasionally need to manage your Personal Data for reasons that extend beyond the direct scope of our contractual agreement with you. We believe we have a justified basis for this, known in legal terms as a 'legitimate interest,' which applies to the scenarios listed below and potentially others:",
      [
        "To assist you with setting up your Account, we'll be sending you multiple email messages. These may include a request to verify your email address or provide guidance on navigating our Online Marketplace.",
        "Our aim is to assist you in locating items that you're enthusiastic about. Therefore, as part of our outreach, we'll be sending you marketing emails, like frequent newsletters and surveys, provided you continue to actively engage with the Outbidda platform.",
        "To ensure you stay informed about Auctions or Raffles you're interested in, we'll send you email notifications. These might include reminders about an Auction or Raffle nearing its end that you're following, or confirmations of your bids.",
        'Our sales team is dedicated to ensuring you have an optimal experience as you begin selling. They might reach out to you through email or phone calls. This could be to offer help with onboarding and setting up your Account, guide you through the process of submitting items for Auctions or Raffles, invite you to special themed and partnership Auctions or Raffles, or to assist in expanding your business on Outbidda.',
        'Our data team plays a key role in analyzing your interactions with our Online Marketplace. They track aspects like your bids, purchases, and the items you auction or put on raffle, as well as the pages and categories you frequently visit and mark as favorites. This analysis is crucial for enhancing our Online Marketplace, ensuring we provide an optimal user experience. Additionally, we utilize this data for market analysis and to refine our sales procedures.',
        'To ensure that Outbidda remains a safe and reliable platform for both Buyers and Sellers, our focus includes identifying and addressing fraud, money laundering, and any other misuse of our Online Marketplace. Our trust and safety operations team utilizes Personal Data to investigate Users who violate our Terms & Conditions. Additionally, we process your Personal Data to maintain system integrity, such as preventing hacking or credential stuffing attacks. This also extends to recording, mediating, and resolving disputes or irregularities, as well as enforcing our Terms & Conditions and other policies.',
        'Our aim is to maintain the reliability of our website for your use. As such, we conduct reviews, scans, and analyses of all activities on our Online Platform. This is essential for fraud prevention, risk assessment, conducting investigations, and providing support.',
        "Lastly, we utilize Personal Data to enforce and execute our company policies and procedures. This includes addressing any claims made against us and safeguarding the rights, property, or safety of Outbidda, our Users, and the public. Additionally, this data may be used in corporate transactions like mergers, sales, reorganizations, acquisitions, bankruptcy, or similar events. It's also employed for other legitimate business activities as allowed by relevant laws.",
      ],
      'Occasionally, we may introduce new data processing activities based on legitimate interest. These will be incorporated into our data protection and privacy notice during its subsequent update.',
      'We have a legal duty to handle specific categories of Personal Data. This obligation covers the processing of Personal Data for reasons including:',
      [
        'To verify the identity of all our Users, we utilize your contact details and, when necessary, a copy of your government-issued ID along with other relevant information. This is in accordance with applicable laws, including those related to anti-money laundering. We may also engage a Third Party Service Provider to assist us in this verification process.',
        'To guarantee that all transactions are accurately reported to tax authorities and subject to proper auditing, we maintain records of transaction and payment data. Furthermore, in some countries, we are required to periodically (at least annually) update national authorities, including tax authorities, about our Sellers and their activities on our platform in the regions where we operate.',
        'In certain jurisdictions, we have a responsibility to identify and remove harmful or illegal content from our Online Marketplace. To achieve this, we may monitor information within Object descriptions and the content of our internal messaging system.',
      ],
      'Lastly, the processing of your Personal Data may occur with your consent. We aim to minimize the frequency of consent requests, reserving them for situations where they are legally required or when no other alternatives exist. With your consent, we utilize your Account information for the following reason:',
      [
        'We strive to make sure that the advertisements you see outside of our Online Marketplace are pertinent to your interests. For this purpose, we might utilize data gathered from cookies and other tracking technologies on your device. This same data can also be employed to tailor the email communications you receive from us, ensuring they are relevant to you.',
        'While we routinely gather basic statistics, obtaining more detailed insights into how you use our Online Marketplace requires your consent. This additional information allows us to gain a deeper understanding of your interaction with our platform.',
        'To gather your input regarding your experience with buying or selling on Outbidda, we process specific Personal Data, like your email address or User ID, solely for our internal analytics. This aids in improving our Services. Additionally, we may handle the opinions, experiences, preferences, interests, and reviews you share with us or other Users, whether communicated directly to us, through social media, or online.',
        'To utilize your images or details of your Objects in our marketing initiatives.',
      ],
    ],
  },
  {
    title: 'Disclosing Information to Third Parties',
    content: [
      'Outbidda serves as the "data controller" for all the Personal Data that we handle. This implies that we are responsible for making decisions regarding the methods and purposes of processing your Personal Data. Consequently, any inquiries you might have about the processing of your Personal Data can be directed to us, even when the processing is carried out by one of our Third Party Service Providers.',
      "In certain instances, it's essential for Outbidda to share your Personal Data with another User of our Online Marketplace. This can happen in various scenarios, all related to facilitating and executing the Sale Contract, such as:",
      [
        'When you purchase an item from another User either through an auction or a raffle, the item needs to be sent to you after payment is received. In this case, we will provide the Seller with your address and contact details (including phone number and email address) to organize the shipment.',
        'If a dispute arises between you and another User, we may exchange address and contact information between the involved Users to assist in resolving the matter directly.',
      ],
      'Upon receiving another User`s Personal Data, you are obliged to use it solely for fulfilling your duties or addressing any issues stemming from an auction or raffle. You must always handle Personal Data in compliance with applicable (data protection) laws and regulations. Without explicit consent from the concerned User, you are prohibited from using their Personal Data for any (other) commercial activities, such as sending marketing messages, unsolicited communications, or contacting the User for such purposes.',
      "Outbidda collaborates with various Third Party Service Providers to assist in processing your Personal Data. These providers contribute in numerous ways, such as offering office software, hosting our website, managing our databases, conducting our advertising campaigns, and other aspects of Personal Data processing. It's important to note that these Third Party Service Providers process your Personal Data strictly according to our directives. Your Personal Data may be processed by the following types of Third Party Service Providers as part of our operational processes:",
      [
        'Providers of customer relationship management software.',
        'Email and messaging service providers.',
        'Web hosting service providers.',
        'Providers of office software.',
        'Payment service providers (this includes services for identity verification).',
        'Online advertisers and advertising networks.',
        'Data analytics service providers.',
        'Social media networks.',
      ],
      "Occasionally, Outbidda is required to share your Personal Data with entities that process the data for their independent purposes, often due to legal obligations. When your Personal Data is processed by any organization for the reasons mentioned below, it's important to understand that they are acting under their own responsibility. These entities may include:",
      [
        'Payment service providers (including services for identity verification).',
        'Government authorities (such as tax and law enforcement agencies).',
        'Auditors.',
        'Debt collection agencies.',
      ],
      'Furthermore, in the event of a merger, (partial) sale, or (partial) transfer of our assets, your Personal Data might be disclosed to one or more other organizations. If such a situation arises, we will endeavor to notify you promptly.',
      "Outbidda engages with users on various social media platforms, including Facebook, Instagram, Twitter, TikTok, and LinkedIn, offering opportunities for interaction. It's important to remember that the terms of use and privacy policies of these specific social media platforms are applicable in all such interactions. Whenever you interact with Outbidda on social media, we process your Username and any other information you choose to share with us. In cases where you reach out for customer support, this information might be linked to your User ID within our customer relationship management systems. Additionally, Outbidda’s sales teams utilize social media and public websites to identify potential Sellers for our platform. You always have the option to inform us if you prefer not to be contacted in the future.",
    ],
  },
  {
    title: 'International Data Transfers',
    content: [
      'Your Personal Data entrusted to Outbidda is primarily processed by Outbidda Ltd. in the UK. However, not all our Third Party Service Providers are based in the UK, leading to the possibility of your Personal Data being processed in other countries, including those outside the European Economic Area (EEA). We adhere to the GDPR, local data protection laws, and guidance from supervisory authorities, even when processing your data abroad.',
      'For transferring Personal Data across borders, we depend on adequacy decisions made by the European Commission. These decisions affirm that a country has data protection regulations akin to those in the GDPR. The current list of such adequacy decisions is publicly accessible. In the absence of an adequacy decision, Outbidda ensures necessary contractual measures with our Third Party Service Providers, like standard contractual clauses and additional data protection measures. We also plan to conduct data transfer risk assessments when creating or updating contracts with these providers, to identify and address any potential challenges posed by foreign laws affecting the security of your Personal Data.',
      "A significant destination for your data outside the EEA is the United States, as many of our service providers are based there and partly use American hosting servers. While we won't delve into extensive technical specifics, we do ensure encryption of all Personal Data, both during transfer and when stored. We also strive to ensure our Third Party Service Providers agree not to share your Personal Data with others without informing us first. Exceptions may occur, such as in cases where a legal warrant or subpoena necessitates the disclosure of your data.",
    ],
  },
];

import React, { useState } from 'react';
import { ListItem, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
const CategoryList = ({ categories, isSelectedCategory, onClose, onCategorySelect }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    onCategorySelect(categoryId);
    onClose();
  };
  return (
    <div>
      <RadioGroup value={isSelectedCategory ? isSelectedCategory : selectedCategory} onChange={handleCategoryChange}>
        {categories
          .filter((category) => category.parent === null)
          .map((category) => (
            <div key={category._id}>
              <ListItem>
                <Typography fontSize={20}>{category.name.en}</Typography>
              </ListItem>
              <div className="ml-8 flex flex-col">
                {categories
                  .filter((subCategory) => subCategory.parent === category._id)
                  .map((subCategory) => (
                    <FormControlLabel
                      key={subCategory._id}
                      value={subCategory._id}
                      control={<Radio />}
                      label={subCategory.name.en}
                    />
                  ))}
              </div>
            </div>
          ))}
      </RadioGroup>
    </div>
  );
};
export default CategoryList;

import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Skeleton } from '@mui/material';
import { CustomButton } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import SwiperComponent from '../../utils/ui/SwiperComponent.jsx';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import axios from '../../config/axiosConfig';

const CategorySwiperSection = ({ item, loadingAll, serchCategoryValue, t, userCurrency }) => {
  const navigate = useNavigate();
  const { isMdScreen, isSgMaxScreen } = useMediaQueries();
  const handleSeeMore = () => {
    serchCategoryValue ? navigate(`/search/*?search=&category=${item?._id}`) : navigate(`/&category=${item?._id}`);
  };

  return (
    <div className={`pt-${isSgMaxScreen ? 4 : 12} px-0 ml-4 md:ml-10 xl:ml-20 pb-10`}>
      <div className="flex justify-between items-end">
        <Typography variant="h5" sx={{ fontWeight: 600 }} className="pt-4">
          {loadingAll ? (
            <Skeleton
              animation="wave"
              height={20}
              sx={{
                fontSize: '25rem',
              }}
            />
          ) : (
            item?.name?.en
          )}
        </Typography>
        {!isMdScreen && <SeeMoreButton onClick={handleSeeMore} t={t} />}
      </div>
      <CategoryItem categoryId={item?._id} userCurrency={userCurrency} />
      {isMdScreen && (
        <div className="flex justify-center pt-6">
          <SeeMoreButton onClick={handleSeeMore} t={t} />
        </div>
      )}
    </div>
  );
};

const CategoryItem = ({ categoryId, userCurrency }) => {
  const { isSgMaxScreen } = useMediaQueries();
  const [categoryProducts, setCategoryProducts] = useState(Array(10).fill(null));
  const [isLoading, setIsLoading] = useState(false);

  const fetchCategoryProducts = useCallback(async (categoryId, userCurrency) => {
    const url = `product?type=All&status=accepted&category=${categoryId}&sort=MostViewed&viewCurrency=${userCurrency}`;
    const response = await axios.get(url);
    return response.data?.list;
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const productsData = await fetchCategoryProducts(categoryId, userCurrency);
      setIsLoading(false);
      setCategoryProducts(productsData);
    } catch (error) {
      console.error('Error fetching category products:', error);
    }
  }, [categoryId, userCurrency, fetchCategoryProducts]);

  useEffect(() => {
    if (categoryId) {
      fetchData();
    }
  }, [fetchData, categoryId]);

  return (
    <div className="pt-4">
      <SwiperComponent
        data={categoryProducts}
        isLoading={isLoading}
        leftCenteredSlides={true}
        sliderWidth={isSgMaxScreen ? 250 : 300}
        sliderOffset={8}
      />
    </div>
  );
};

const SeeMoreButton = ({ onClick, t }) => {
  const { isSdScreen, isSgScreen, isMdScreen, isSgMaxScreen } = useMediaQueries();
  return (
    <CustomButton
      variant="contained"
      onClick={onClick}
      sx={{ mx: 1, px: isSgMaxScreen ? 0 : null }}
      style={{
        width: isMdScreen ? '100%' : isSgScreen ? '45%' : isSdScreen ? '38%' : isSgMaxScreen ? '40%' : '50%',
        maxWidth: isMdScreen ? 205 : 250,
      }}
    >
      <Typography fontSize={isMdScreen && 18}>{t('See more')}</Typography>
    </CustomButton>
  );
};

export default CategorySwiperSection;

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from '../../config/axiosConfig';
import { Typography, RadioGroup, Radio, FormControlLabel, Grid, Collapse } from '@mui/material';

import { selectUser } from '../../store/features/userSlice';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import { baseURLImage } from '../../utils/constants';

import { CustomButton } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const QuestionForm = ({ open, onClose, onClick, category }) => {
  const { t } = useTranslation();
  const { isSdScreen, isSgScreen } = useMediaQueries();
  const user = useSelector(selectUser);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedAnswerError, setSelectedAnswerError] = useState('');
  const [quizQuestion, setQuizQuestion] = useState(null);
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
    setSelectedAnswerError('');
  };

  const handleSubmit = () => {
    if (selectedAnswer) {
      onClick(quizQuestion?._id, selectedAnswer);
    } else {
      setSelectedAnswerError('Choose the answer');
    }
  };

  const handleCloseForm = () => {
    if (onClose) {
      onClose();
      setSelectedAnswer(null);
      setSelectedAnswerError('');
    }
  };

  const fetchData = useCallback(() => {
    if (user.role) {
      axios
        .get('quiz/category/' + category)
        .then(function (response) {
          setQuizQuestion(response.data);
        })
        .catch(function (error) {
          showSnackbarRef.current(error);
        });
    }
  }, [category, user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <FormTemplate
        open={open}
        onClose={handleCloseForm}
        dialogContent={
          <>
            <img
              src={baseURLImage + quizQuestion?.photo}
              style={{
                maxWidth: isSgScreen ? 300 : isSdScreen ? 200 : 180,
                maxHeight: isSgScreen ? 300 : isSdScreen ? 200 : 180,
                borderRadius: '10px',
                boxShadow: '0px 4px 4px 0px #00000040',
              }}
              alt="Question Img"
            />
            <Typography mt={4} align="center" variant="h6">
              {quizQuestion?.title?.en}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                pt: 2,
                width: '100%',
                margin: 'auto',
              }}
              className="flex justify-center items-center"
            >
              <RadioGroup
                value={selectedAnswer}
                onChange={handleAnswerChange}
                row={isSdScreen}
                sx={{
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {quizQuestion?.answers &&
                  quizQuestion?.answers.map((answer, index) => (
                    <Grid item xs={6} key={index} className="pl-4">
                      <FormControlLabel value={index} control={<Radio />} label={answer?.en} />
                    </Grid>
                  ))}
              </RadioGroup>
            </Grid>
            <Typography mt={2} fontSize={14} align="center" className="flex items-center">
              <InfoOutlinedIcon />
              <span>
                {t('You will be applied to the list of challengers only if the question was answered correctly.')}
              </span>
            </Typography>
            <Collapse in={!!selectedAnswerError}>
              <Typography color="error" className="text-red-600">
                {selectedAnswerError || '\u00A0'}{' '}
              </Typography>
            </Collapse>
          </>
        }
        dialogActions={
          <CustomButton variant="contained" onClick={handleSubmit}>
            {t('Save')}
          </CustomButton>
        }
      />
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default QuestionForm;

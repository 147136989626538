import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../../config/axiosConfig';
import { setAccessToken, setRefreshToken } from '../../../store/features/authSlice';
import { setUser, setUserCurrency } from '../../../store/features/userSlice';
import { selectPreviousUrl } from '../../../store/features/urlSlice';
import SnackbarComponent from '../../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../../utils/ui/js/snackbarUtils';
import FormTemplate from '../../../utils/ui/forms/FormTemplate.jsx';
import SingUpAdditionalAutocomplites from './SingUpAdditionalAutocomplites.jsx';

const SignUpWithGoogleAuthForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useParams();
  const { t } = useTranslation();

  const previousUrl = useSelector(selectPreviousUrl);
  const [isUserProfileConfiguration, setIsUserProfileConfiguration] = useState(false);
  const [isOpenedSuccessForm, setIsOpenedSuccessForm] = useState(true);
  const location = useLocation();
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);

  const navigateToPreviousPage = useCallback(() => {
    if (previousUrl && previousUrl !== '/') {
      if (previousUrl === '/login') {
        navigate(-4);
      } else if (location.pathname !== previousUrl) {
        navigate(previousUrl);
      } else if (previousUrl.includes('/success-signin')) {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate, previousUrl, location.pathname]);

  const fetchData = useCallback(() => {
    if (hash) {
      axios
        .get('auth/session/' + hash)
        .then(function (response) {
          dispatch(setUser(response.data.user));
          dispatch(setAccessToken(response.data.auth.accessToken));
          dispatch(setRefreshToken(response.data.auth.refreshToken));
          if (!response.data.user?.currency) {
            dispatch(
              setUserCurrency(
                process.env.REACT_APP_NODE_ENV === 'production'
                  ? '6606ac954fbf9c7941c5e7fb'
                  : '65158717bb30bd020a1ca060'
              )
            );
          }

          const isUserProfileConfiguration =
            response.data.user && (!response.data.user?.country || response.data.user?.country === undefined);
          if (isUserProfileConfiguration) {
            setIsUserProfileConfiguration(isUserProfileConfiguration);
          } else {
            navigateToPreviousPage();
          }
        })
        .catch(function (error) {
          showSnackbarRef.current(error);
        });
    }
  }, [hash, dispatch, setIsUserProfileConfiguration, navigateToPreviousPage]);

  const handleRedirect = () => {
    setIsOpenedSuccessForm(false);
    navigateToPreviousPage();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {isUserProfileConfiguration && (
        <>
          <FormTemplate
            open={isOpenedSuccessForm}
            onClose={handleRedirect}
            title={t('Please complete your profile configuration')}
            dialogContent={<SingUpAdditionalAutocomplites additional={true} onClose={handleRedirect} />}
          />
        </>
      )}
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default SignUpWithGoogleAuthForm;

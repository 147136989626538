<template>
  <div class="chat">
    <div class="chat-container">
      <vue-advanced-chat
        :height="this.supportChatHeight"
        :current-user-id="this.userId"
        :rooms="JSON.stringify(rooms)"
        :messages="JSON.stringify(messages)"
        :room-actions="JSON.stringify(roomActions)"
        :show-add-room="'false'"
        :messages-loaded="messagesLoaded"
        :loading-rooms="loadingRooms"
        :rooms-loaded="roomsLoaded"
        :show-audio="'false'"
        :show-emojis="'false'"
        :show-reaction-emojis="'false'"
        :link-options="JSON.stringify(linkOptions)"
        :accepted-files="acceptedFiles"
        :single-room="this.isSupportChat"
        :room-id="supportRoomId"
        @send-message="sendMessage($event.detail[0])"
        @room-action-handler="roomActionHandler($event.detail[0])"
        @fetch-messages="fetchMessages($event.detail[0])"
        @open-file="openFile($event.detail[0])"
      >
        <div v-if="!isSendLoader" :slot="'send-icon'"><svg-icon type="mdi" :path="mdiSend"></svg-icon></div>
        <div v-else :slot="'send-icon'" class="spinner"></div>
        <div v-if="this.isSupportChat" :slot="'room-options'">
          <button @click="handleCloseSupportChat">
            <svg-icon type="mdi" :path="mdiClose"></svg-icon>
          </button>
        </div>
      </vue-advanced-chat>
      <div v-if="fileErrorMessage" class="error-massage">
        <span class="error-massage-span">{{ fileErrorMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client';
import { register } from 'vue-advanced-chat';
import SvgIcon from '@jamescoyle/vue-icon';
import axios from '../../../config/axiosConfig';
import SnackbarComponent from '../../../utils/ui/SnackbarComponent.jsx';

import { mdiSend, mdiClose } from '@mdi/js';
import { baseURLImage as backendURL, supportUserId } from '../../../utils/constants';
import eventBus from '../../../utils/functions/eventBus.js';
import accountIcon from '../../../assets/icons/accountIcon.svg';

class Message {
  constructor(data, userId, files) {
    this._id = data?._id;
    this.senderId = data.senderId;
    this.content = data.content;
    this.username = data?.senderId === userId ? userId : data?.senderId;
    this.timestamp = new Date(data.createdAt || data.updatedAt).toString().substring(16, 21);
    this.files = files;
    this.createdAt = data.createdAt;
  }
}

register();
export default {
  name: 'ChatComponent',
  data() {
    return {
      users: [],
      rooms: [],
      messages: [],
      roomsLoaded: false,
      loadingRooms: true,
      messagesLoaded: false,
      roomActions: [{ name: 'deleteRoom', title: 'Delete Chat' }],
      linkOptions: { disabled: true, target: '_blank', rel: null },
      currentRoomId: null,
      totalUnreadMessages: null,
      isSendLoader: false,
      acceptedFiles: 'image/png, image/jpeg, image/jpg, application/pdf, video/mp4',
      accountIcon,
      fileErrorMessage: null,
      supportRoomId: null,
      supportChatHeight: null,
      mdiSend,
      mdiClose,
    };
  },
  components: { SnackbarComponent, SvgIcon },
  props: {
    userId: String,
    isSupportChat: Boolean,
  },
  mounted() {
    this.fetchRooms();
    this.initSocketListeners();
    this.calculateChatHeight();
  },
  methods: {
    initSocketListeners() {
      try {
        this.socket = io(backendURL, {
          transports: ['websocket'],
        });
        this.socket.emit('join', { userId: this.userId });
        this.socket.on('server-msg', (message) => {
          try {
            let parsedMessage = typeof message === 'string' ? JSON.parse(message) : message;
            this.handleSocketMessage(parsedMessage, parsedMessage.updatedAt);
          } catch (e) {
            console.error('Error parsing server-msg:', e);
          }
        });

        this.socket.on('msg-sent', (message) => {
          try {
            let parsedMessage = typeof message === 'string' ? JSON.parse(message) : message;
            this.handleSocketMessage(parsedMessage, parsedMessage.createdAt);
          } catch (e) {
            console.error('Error parsing msg-sent:', e);
          }
        });
      } catch (e) {
        console.log('Error inside socket init.', e);
      }
    },
    formatChatUsers(chat) {
      const chatUsers = [];
      for (const [k, user] of Object?.entries(chat.users)) {
        chatUsers.push({
          _id: k,
          username: `${user.firstName} ${user.lastName}`,
          avatar: user?.avatar || '',
        });
      }
      return chatUsers;
    },
    async handleSocketMessage(message, timestamp) {
      let files = [];
      if (message.media && message.media.media.length > 0) {
        files = await this.processFiles(message.media.media[0], message.media._id);
      }

      const newMessage = new Message(message, this.userId, files);
      newMessage.timestamp = new Date(timestamp).toString().substring(16, 21);
      const room = this.rooms.find((item) => item.roomId === message?.chatId);
      if (room) {
        room.lastMessage = { ...newMessage };
      }
      if (this.currentRoomId === message?.chatId) {
        this.messages.push(newMessage);
      }
    },
    async fetchRooms() {
      const { data: rooms } = await axios.get(`chat/by-user/all/${this.userId}`);
      this.currentRoomId = rooms?.[0]?._id;

      if (this.isSupportChat) {
        let supportUserRoom = rooms?.find((room) => Object?.keys(room?.users)?.find((user) => user === supportUserId));
        this.supportRoomId = supportUserRoom?._id;
      }
      const formattedRooms = rooms?.map((chat) => {
        let recepterUser = Object?.keys(chat?.users)?.find((user) => user !== this.userId);
        let userData = chat?.users?.[recepterUser];

        return {
          roomId: chat._id,
          roomName: `${userData?.firstName} ${userData?.lastName}`,
          avatar: userData?.picture ? `${backendURL}${userData?.picture}` : accountIcon,
          unreadCount: chat?.unread?.[this.userId],
          index: 0,
          lastMessage: null,
          users: this.formatChatUsers(chat),
          typingUsers: [],
        };
      });

      let totalUnread = 0;

      rooms.forEach((room) => {
        if (room.unread) {
          if (room.unread[this.userId]) {
            totalUnread += room.unread[this.userId];
          }
        }
      });
      this.handleUpdateUnreadMessages(totalUnread);

      this.loadingRooms = !this.loadingRooms;
      this.roomsLoaded = !this.roomsLoaded;
      this.rooms = formattedRooms;
    },
    async fetchMessages({ room, options }) {
      this.messagesLoaded = false;
      this.messages = [];
      try {
        const roomId = this.isSupportChat ? this.supportRoomId : room.roomId;
        const [chatMessagesResponse] = await Promise.all([
          axios.get(`chat/history/${roomId}`),
          axios.put(`chat/${roomId}/unread`, {
            userId: this.userId,
            count: 0,
          }),
        ]);
        const chatMessages = chatMessagesResponse.data;
        this.currentRoomId = roomId;
        let parsedMessages = typeof chatMessages === 'string' ? JSON.parse(chatMessages) : chatMessages;
        let unreadCount = Number(
          this.totalUnreadMessages - this.rooms.find((item) => item.roomId === roomId).unreadCount
        );
        this.handleUpdateUnreadMessages(unreadCount);
        this.rooms.find((item) => item.roomId === roomId).unreadCount = 0;
        let newMessages = [];
        await Promise.all(
          parsedMessages.map(async (message) => {
            let files = [];
            if (message.media && message.media.media.length > 0) {
              files = await this.processFiles(message.media.media[0], message.media._id);
            }
            const newMessage = new Message(message, this.userId, files);
            newMessages.unshift(newMessage);
          })
        );
        newMessages.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.messages = [];
        newMessages.reverse();
        this.messages.push(...newMessages);

        this.messagesLoaded = true;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },

    async sendMessage({ roomId, content, files, replyMessage, usersTag }) {
      this.isSendLoader = true;
      const roomIndex = this.rooms.findIndex(({ roomId: rId }) => rId === roomId);
      const forbiddenRegex =
        /(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|\+?\d{8,15}/gi;
      if (forbiddenRegex.test(content)) {
        this.showFileErrorMessage('Links are not allowed');
      } else {
        const recipientId = this.rooms[roomIndex].users.filter(({ id }) => id !== this.userId)[0].id;
        if (files) {
          const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'video/mp4'];
          const formData = new FormData();
          let isReturn = false;
          files.forEach((file) => {
            const { blob: extractedFile, name } = Object?.assign({}, file);
            const fileType = extractedFile?.type;
            if (allowedFileTypes?.includes(fileType)) {
              const extension = extractedFile?.type === 'image/jpeg' ? 'jpg' : extractedFile?.type.split('/')[1];
              const fileName = name ? `${name}.${extension}` : `file_${Date.now()}.${extension}`;
              const fileWithMetadata = new File([extractedFile], fileName, { type: extractedFile?.type });
              formData.append('files', fileWithMetadata);
            } else {
              this.showFileErrorMessage('This file type is not supported');
              isReturn = true;
            }
          });
          if (!isReturn) {
            const {
              data: { _id: mediaId },
            } = await axios.post(`chat/${roomId}/uploads`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            await this.socket.emit('msg', {
              content: content ? content : files ? ' ' : null,
              chatId: roomId,
              recipientId,
              senderId: this.userId,
              media: mediaId,
            });
          }
        } else {
          this.socket.emit('msg', {
            content: content,
            chatId: roomId,
            recipientId,
            senderId: this.userId,
          });
        }
      }
      this.isSendLoader = false;
    },
    async processFiles(mediaUrl, _id) {
      try {
        const fileInfo = await this.fetchAndDisplayImage(`${backendURL}${mediaUrl}`);
        return [
          {
            name: _id,
            size: fileInfo?.size,
            type: fileInfo?.type,
            url: fileInfo?.imageUrlObjectURL,
            preview: fileInfo?.imageUrlObjectURL,
          },
        ];
      } catch (error) {
        console.error('Error processing file:', error);
        return [];
      }
    },
    async fetchAndDisplayImage(imageUrl) {
      try {
        const response = await axios.get(imageUrl);
        const size = response?.headers['content-length'];

        const type = imageUrl.split('.').pop();

        return {
          imageUrlObjectURL: imageUrl,
          size,
          type,
        };
      } catch (error) {
        console.error('Error fetching and displaying image:', error);
      }
    },
    roomActionHandler({ roomId, action }) {
      switch (action.name) {
        case 'archiveRoom':
          console.log('archiveRoom');
          break;
        case 'deleteRoom':
          this.deleteRoom(roomId);
          break;
      }
    },
    async deleteRoom(roomId) {
      try {
        await axios.delete(`chat/${roomId}`);
        this.rooms = this.rooms.filter((room) => room.roomId !== roomId);
      } catch (error) {
        console.error('Error deleting room:', error);
      }
    },
    handleUpdateUnreadMessages(value) {
      if (value != null) {
        eventBus.$emit('updateUnreadMessages', value);
        this.totalUnreadMessages = value;
      }
    },
    openFile({ message, file }) {
      if (file.action === 'download') {
        const downloadLink = document.createElement('a');
        downloadLink.href = file?.file?.url;
        downloadLink.download = file?.file?.name;
        downloadLink.target = '_blank';
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },
    showFileErrorMessage(message) {
      this.fileErrorMessage = message;
      setTimeout(() => {
        this.fileErrorMessage = null;
      }, 3000);
    },
    handleCloseSupportChat() {
      eventBus.$emit('closeSupportChat', false);
    },
    calculateChatHeight() {
      const offsetHeight = window.innerHeight - (window.innerHeight * 30) / 100;
      this.supportChatHeight = offsetHeight <= 600 ? `${offsetHeight}px` : `600px`;
    },
  },
};
</script>

<style scoped>
.chat-container {
  padding: 1rem 1rem 2rem 1rem;
  position: relative;
}
.spinner {
  width: 25px;
  height: 25px;
  position: relative;
  margin: 0 auto;
}

.spinner::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border: 3px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error-massage {
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.error-massage-span {
  position: absolute;
  bottom: 6rem;
  color: rgb(116, 16, 16);
  font-size: 22px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .chat-container {
    padding: 1rem 1.5rem 2rem 1.5rem;
  }
}
</style>

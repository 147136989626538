import React from 'react';
import { Snackbar, Alert } from '@mui/material';

function SnackbarComponent({ open, onClose, action, direction, vertical, horizontal, severity, message }) {
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        action={action}
        direction={direction}
        anchorOrigin={{
          vertical: vertical ? vertical : 'bottom',
          horizontal: horizontal ? horizontal : 'center',
        }}
      >
        <Alert variant="filled" severity={severity} sx={{ width: '100%', borderRadius: '10px' }}>
          <strong>{message}</strong>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SnackbarComponent;

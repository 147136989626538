import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMediaQueries } from '../../../utils/ui/js/useMediaQueries';
import { Card, Typography, Grid, List, Divider, IconButton } from '@mui/material';

import TermsPolicyDialog from '../../forms/singup/TermsPolicyDialog.jsx';
import { LINKS } from '../../../utils/constants';

import instagramIcon from '../../../assets/icons/instagramIcon.svg';
import visaIcon from '../../../assets/icons/visaIcon.svg';
import mastercardIcon from '../../../assets/icons/mastercardIcon.svg';

function Footer() {
  const { isSgMaxScreen, isMdScreen, isLgScreen } = useMediaQueries();
  const { t } = useTranslation();
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);

  const handleClickOpenPolicyModal = (isPrivacyPolicy) => () => {
    setOpenPolicyModal(true);
    setIsPrivacyPolicy(isPrivacyPolicy);
  };

  return (
    <>
      <Card className=" px-12" sx={{ minHeight: 345, marginTop: 'auto' }}>
        <Grid container spacing={2} className="py-16">
          <Grid
            item
            xs={isLgScreen ? 6 : isMdScreen ? 4 : 12}
            className="flex"
            style={{
              justifyContent: isMdScreen || isSgMaxScreen ? 'start' : 'center',
            }}
          >
            <Typography
              className="text-black font-bold navbar-font-family"
              variant="h1"
              fontSize={32}
              ml={isSgMaxScreen ? 0 : '0.5rem'}
            >
              {t('outbidda')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={isLgScreen ? 6 : isMdScreen ? 8 : 12}
            style={{
              flexDirection: isMdScreen ? 'row' : 'column',
              alignItems: isMdScreen || isSgMaxScreen ? 'start' : 'center',
            }}
            className="flex justify-between"
          >
            <div className="flex justify-between flex-col md:flex-row items-start md:w-full">
              <List className="flex justify-center flex-col">
                <Typography fontSize={20} fontWeight={500} mb={1}>
                  {t('Follow us on')}
                </Typography>
                <Link to={LINKS.INSTAGRAM} target="_blank" style={{ maxWidth: 26 }}>
                  <IconButton sx={{ padding: 0, width: 26, height: 26 }}>
                    <img src={instagramIcon} alt="Instagram Icon" />
                  </IconButton>
                </Link>
              </List>
              <List className="flex flex-col items-start">
                <Typography fontSize={20} fontWeight={500} mb={2}>
                  {t('Policies')}
                </Typography>
                <button onClick={handleClickOpenPolicyModal(false)}>
                  <Typography mb={2}>{t('Terms & Conditions')}</Typography>
                </button>
                <button onClick={handleClickOpenPolicyModal(true)}>
                  <Typography>{t('Privacy Policy')}</Typography>
                </button>
              </List>
              <List>
                <Typography fontSize={20} fontWeight={500} mb={2}>
                  {t('Help')}
                </Typography>
                <Link to="/about-us">
                  <Typography mb={2}> {t('About Outbidda')}</Typography>
                </Link>
                <Link to="/contact-us">
                  <Typography mb={2}> {t('Contact us')}</Typography>
                </Link>
                <Link to="/faq">
                  <Typography> {t('FAQ')}</Typography>
                </Link>
              </List>
            </div>
          </Grid>
        </Grid>
        <div className="flex flex-row justify-center md:justify-end px-10 pb-4">
          <img src={visaIcon} alt="Visa Icon" style={{ width: '29px' }} />
          <img className="ml-2" src={mastercardIcon} alt="Master Card Icon" style={{ width: '29px' }} />
        </div>
        <Divider />
        <Typography variant="body2" align={isMdScreen ? 'left' : 'center'} sx={{ mb: '1rem', py: '2rem' }}>
          {'© All rights reserved. Outbidda ltd'}
        </Typography>
      </Card>
      <TermsPolicyDialog
        open={openPolicyModal}
        handleClosePolicyModal={() => {
          setOpenPolicyModal(false);
        }}
        isPrivacyPolicy={isPrivacyPolicy}
        t={t}
      />
    </>
  );
}

export default Footer;

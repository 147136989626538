import React from 'react';
import { Typography, Card } from '@mui/material';
import { baseURLImage } from '../../utils/constants';
import CardComponent from './CardTicketComponent.jsx';

import congratulationsIcon from '../../assets/icons/congratulationsIcon.svg';
import accountIcon from '../../assets/icons/accountIcon.svg';
function WinnerObserverCardComponents({ customer, isRaffle, acceptedOrderList, highestBid, winnerUserTicketNumber }) {
  return (
    <>
      <Card
        className="p-6 w-full"
        style={{
          borderRadius: '0.75rem',
          maxWidth: 640,
        }}
        sx={{ minHeight: 240 }}
      >
        <div className="flex flex-col w-full justify-between items-center">
          <Typography align="center" fontSize={20}>
            The Winner
          </Typography>
          <div className="pt-4 flex justify-evenly w-full items-center">
            <img src={congratulationsIcon} alt="Congratulations Icon" style={{ width: 30, height: 30 }} />
            <img
              src={!customer?.user?.picture ? accountIcon : baseURLImage + customer?.user?.picture}
              style={{
                width: '121px',
                height: '121px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              alt="Winner Icon"
            />
            <img src={congratulationsIcon} alt="Congratulations Icon" style={{ width: 30, height: 30 }} />
          </div>
          <Typography className="underline" align="center" fontSize={20} fontWeight={500} mt={2}>
            {`${customer?.user?.firstName} ${customer?.user?.lastName}`}
          </Typography>
          <Typography align="center" fontSize={20} mt={4} fontWeight={300}>
            {isRaffle ? 'Ticket' : 'Winning bid'}
          </Typography>
          <CardComponent
            value={
              !isRaffle
                ? acceptedOrderList?.[0]?.converter?.currency?.abbr_name + ' ' + highestBid
                : winnerUserTicketNumber
            }
          />
        </div>
      </Card>
    </>
  );
}

export default WinnerObserverCardComponents;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';

import store, { persistor } from './store/store';
import './index.css';
import App from './App.jsx';
import i18n from './i18n';

import ScrollToTop from './utils/router/ScrollToTop.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <ScrollToTop />
            <App />
          </Router>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

import { createSlice } from '@reduxjs/toolkit';

const urlSlice = createSlice({
  name: 'url',
  initialState: {
    previousUrl: null,
  },
  reducers: {
    setPreviousUrl: (state, action) => {
      state.previousUrl = action.payload;
    },
  },
});

export const { setPreviousUrl } = urlSlice.actions;

export const selectPreviousUrl = (state) => state.url.previousUrl;

export default urlSlice.reducer;

import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, Collapse, Typography } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';
import { CustomTextTypography } from './StyledNavbarComponent.jsx';

const SidebarItem = ({
  label,
  icon,
  isOpen,
  onClick,
  to = null,
  subItems = null,
  onCloseSideBar,
  labelAdditionally = null,
}) => {
  const handleClick = () => {
    if (onCloseSideBar) {
      onCloseSideBar();
    }
    if (onClick) {
      onClick();
    }
  };
  return (
    <>
      <ListItemButton sx={{ px: 4, ml: 4, py: 2 }} onClick={handleClick} component={to ? Link : 'div'} to={to}>
        <ListItemIcon>{icon && <img src={icon} alt="" style={{ width: '26px', height: '26px' }} />}</ListItemIcon>
        <CustomTextTypography fontSize={18} className="flex justify-between w-full">
          {label}
          {labelAdditionally && (
            <>
              <span className=" w-[20px] h-[20px] flex items-center justify-center rounded-full bg-[#AA0000]">
                <Typography fontSize={10} className="text-xs text-[#FFFFFF]" noWrap>
                  {labelAdditionally}
                </Typography>
              </span>
            </>
          )}
        </CustomTextTypography>
        {subItems && isOpen && <ExpandLess />}
      </ListItemButton>
      {subItems && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems.map((subItem, index) => (
              <ListItemButton key={index} sx={{ pl: 14, py: 2 }}>
                <ListItemIcon>
                  <img src={subItem.icon} alt={`${subItem.label} Icon`} />
                </ListItemIcon>
                <CustomTextTypography>{subItem.label}</CustomTextTypography>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default SidebarItem;

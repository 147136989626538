export function formatDateTime(dateValue, isEnd) {
  const options = {
    year: 'numeric',
    month: isEnd ? 'long' : '2-digit',
    day: isEnd ? 'numeric' : '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const date = new Date(dateValue);
  const formattedDateTime = date.toLocaleDateString('en-US', options);

  const formattedDate = formattedDateTime.split(',')[0]?.trim();
  const formattedTime = formattedDateTime.split(',')[1]?.trim();

  let timeToEndText = '';

  if (isEnd) {
    const timeDiff = date.getTime() - Date.now();
    const daysEnd = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const remainingHours = timeDiff % (1000 * 60 * 60 * 24);
    const hoursEnd = Math.floor(remainingHours / (1000 * 60 * 60));
    const remainingMinutes = remainingHours % (1000 * 60 * 60);
    const minutesEnd = Math.floor(remainingMinutes / (1000 * 60));

    if (daysEnd > 0) {
      timeToEndText += `${daysEnd} day${daysEnd === 1 ? '' : 's'}, `;
    }
    if (hoursEnd > 0) {
      timeToEndText += `${hoursEnd} hour${hoursEnd === 1 ? '' : 's'}, `;
    }
    if (minutesEnd > 0) {
      timeToEndText += `${minutesEnd} minute${minutesEnd === 1 ? '' : 's'}`;
    }
  }
  return {
    date: formattedDate,
    time: formattedTime,
    end: formattedDateTime,
    timeToEnd: isEnd ? timeToEndText : undefined,
  };
}

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from '../../../config/axiosConfig';
import LoginForm from '../../forms/LoginForm.jsx';
import { useTranslation } from 'react-i18next';
import { setUserCurrency, selectUser } from '../../../store/features/userSlice';
import { selectUnreadMessages, setUnreadMessages } from '../../../store/features/userUnreadMessagesSlice';

import SidebarItem from './SideBarComponent.jsx';
import SelectCurrency from './SelectCurrency.jsx';

import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  InputAdornment,
  List,
  ListItemIcon,
  Toolbar,
  Typography,
  Grid,
  Breadcrumbs,
} from '@mui/material';

import { menuItems } from '../../../assets/data/data.js';
import SnackbarComponent from '../../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../../utils/ui/js/snackbarUtils';
import { useMediaQueries } from '../../../utils/ui/js/useMediaQueries';
import { baseURLImage } from '../../../utils/constants';
import LoadingIndicator from '../../../utils/ui/LoadingIndicator.jsx';
import performLogout from '../../../utils/requests/logout';
import eventBus from '../../../utils/functions/eventBus.js';

import { Menu as MenuIcon, SearchRounded as SearchRoundedIcon } from '@mui/icons-material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import createRaffleOrAuctionIcon from '../../../assets/icons/createRaffleOrAuctionIcon.svg';
import contactUsIcon from '../../../assets/icons/contactUsIcon.svg';
import aboutUsIcon from '../../../assets/icons/aboutUsIcon.svg';
import faqIcon from '../../../assets/icons/faqIcon.svg';
import accountIcon from '../../../assets/icons/accountIcon.svg';
import logoutIcon from '../../../assets/icons/logoutIcon.svg';

import { CustomSearchInput } from './StyledNavbarComponent.jsx';

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSdScreen, isMdScreen, isSgMaxScreen } = useMediaQueries();
  const user = useSelector(selectUser);
  const userUnreadMessages = useSelector(selectUnreadMessages);
  const location = useLocation();
  const otherPagesPaths = ['about-us', 'contact-us', 'faq', 'profile', 'create-raffle-or-auction', 'chat'];

  const [searchValue, setSearchValue] = useState([]);
  const [categories, setCategories] = useState([null]);
  const [subcategories, setSubcategories] = useState([null]);
  const [toggleState, setToggleState] = useState({
    sideBar: false,
    userSideBar: false,
    fashion: false,
    collectibles: false,
    accessories: false,
    loginDialog: false,
  });
  const [isTyping, setIsTyping] = useState(false);
  const [isLoadingLoginForm, setIsLoadingLoginForm] = useState(false);
  const [userUnreadMessagesValue, setUserUnreadMessagesValue] = useState(
    userUnreadMessages?.unreadMessages ? userUnreadMessages?.unreadMessages : null
  );
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();
  const showSnackbarRef = useRef(showSnackbar);

  const toggleClick = (key) => {
    setToggleState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleToggleSideBar = () => {
    toggleClick('sideBar');
  };
  const handleToggleUserSideBar = () => {
    toggleClick('userSideBar');
  };

  const delayedSearch = useCallback(
    (searchText) => {
      if (isTyping) {
        const currentPath = window.location.pathname;
        const currentSearch = window.location.search;

        if (currentSearch) {
          const searchParams = new URLSearchParams(currentSearch);
          searchParams.set('search', searchText);
          const newUrl = `${currentPath}?${searchParams.toString()}`;
          navigate(newUrl, { replace: true });
        } else {
          const newUrl =
            currentPath.includes('/product') || currentPath.includes('/profile') || currentPath.includes('/chat')
              ? `/*?search=${searchText}`
              : `${currentPath}*?search=${searchText}`;
          navigate(`search${newUrl}`, { replace: true });
        }
      }
    },
    [navigate, isTyping]
  );

  const handleInputChange = useCallback(({ target: { value } }) => {
    setSearchValue(value);
    setIsTyping(true);
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleInputChange(event);
      }
    },
    [handleInputChange]
  );

  if (user?.currency === null) {
    dispatch(
      setUserCurrency(
        process.env.REACT_APP_NODE_ENV === 'production' ? '6606ac954fbf9c7941c5e7fb' : '65158717bb30bd020a1ca060'
      )
    );
  }

  const handleLogout = async () => {
    try {
      await axios.post('auth/logout');
      performLogout(dispatch);
      handleToggleUserSideBar();
      navigate(`/`);
      showSnackbar(null, 'Success', 'success');
    } catch (error) {
      showSnackbar(error);
    }
  };

  const handleFetchData = useCallback(async () => {
    try {
      const categoryResponse = await axios.get('category/list?status=accepted');
      setCategories(
        categoryResponse.data
          .filter((item) => item.parent === null && item.showInMenu === true)
          .sort((a, b) => a?.name?.en?.localeCompare(b?.name?.en))
      );
      setSubcategories(categoryResponse.data.filter((item) => item.parent != null));
    } catch (error) {
      showSnackbarRef.current(error);
    }
  }, []);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      delayedSearch(searchValue);
      setIsTyping(false);
    }, 2000);

    return () => clearTimeout(timerId);
  }, [searchValue, delayedSearch]);

  useEffect(() => {
    const updateUnreadMessages = (unreadMessages) => {
      dispatch(setUnreadMessages(Number(unreadMessages)));
      setUserUnreadMessagesValue(Number(unreadMessages));
    };
    eventBus.$on('updateUnreadMessages', updateUnreadMessages);
    return () => {
      eventBus.$off('updateUnreadMessages', updateUnreadMessages);
    };
  }, [dispatch]);

  const fetchRoomsData = useCallback(async () => {
    try {
      if (user?._id) {
        const { data: rooms } = await axios.get(`chat/by-user/all/${user._id}`);
        let totalUnread = 0;
        rooms.forEach((room) => {
          if (room.unread && room.unread[user._id]) {
            totalUnread += room.unread[user._id];
          }
        });
        dispatch(setUnreadMessages(Number(totalUnread)));
        setUserUnreadMessagesValue(Number(totalUnread));
      }
    } catch (error) {
      showSnackbarRef.current(error);
    }
  }, [dispatch, user._id]);

  useEffect(() => {
    const intervalId = setInterval(fetchRoomsData, 900000);
    fetchRoomsData();
    return () => clearInterval(intervalId);
  }, [fetchRoomsData]);

  useEffect(() => {
    setUserUnreadMessagesValue(userUnreadMessages?.unreadMessages ? userUnreadMessages.unreadMessages : null);
  }, [userUnreadMessages]);

  return (
    <>
      <nav>
        <Box component="nav">
          <AppBar
            className="pt-4"
            position="fixed"
            style={{ backgroundColor: '#FFFEFE', paddingTop: isSgMaxScreen ? 2 : null }}
            elevation={0}
          >
            <Toolbar sx={{ px: isSdScreen ? null : 1 }}>
              <div className="flex justify-between w-full" style={{ position: 'relative' }}>
                <IconButton
                  onClick={handleToggleSideBar}
                  sx={{ width: 48, height: 48 }}
                  disabled={location.pathname === '/you-banned'}
                >
                  <MenuIcon />
                </IconButton>
                <Link
                  to={location.pathname === '/you-banned' ? '/you-banned' : '/'}
                  style={{
                    position: 'absolute',
                    left: `calc(50% - ${isSgMaxScreen ? 48 : 63}px)`,
                    top: `${isSgMaxScreen ? '8px' : 'unset'}`,
                  }}
                >
                  <Typography
                    variant="h1"
                    align="center"
                    fontSize={isSgMaxScreen ? 24 : 32}
                    fontWeight={700}
                    color="#000000"
                    className="navbar-font-family"
                  >
                    outbidda
                  </Typography>
                </Link>
                <ListItemIcon className="flex justify-center items-center">
                  {user.role ? (
                    <>
                      <IconButton onClick={handleToggleUserSideBar} className="relative">
                        <div className="flex flex-row justify-start items-start relative">
                          <img
                            src={!user.picture ? accountIcon : baseURLImage + user.picture}
                            style={{
                              width: '32px',
                              height: '32px',
                              borderRadius: 20,
                              objectFit: 'cover',
                            }}
                            alt="Account Icon"
                          />
                          {userUnreadMessagesValue !== null && userUnreadMessagesValue !== 0 && (
                            <>
                              <span className="absolute right-[-20px] w-[20px] h-[20px] flex items-center justify-center rounded-full bg-[#AA0000]">
                                <Typography fontSize={10} className="text-xs text-[#FFFFFF]" noWrap>
                                  {userUnreadMessagesValue}
                                </Typography>
                              </span>
                            </>
                          )}
                        </div>
                      </IconButton>
                    </>
                  ) : (
                    <button
                      onClick={() => toggleClick('loginDialog')}
                      disabled={location.pathname === '/you-banned'}
                      style={{
                        fontSize: isSgMaxScreen ? 14 : 16,
                      }}
                    >
                      <LoadingIndicator mx={4.9} isLoading={isLoadingLoginForm} value={t('Log in/Sign up')} />
                    </button>
                  )}
                </ListItemIcon>
              </div>
              <Drawer open={toggleState.sideBar} anchor="left" onClose={handleToggleSideBar}>
                <List style={{ minWidth: isMdScreen ? '20vw' : '95vw' }}>
                  <ListItemIcon className="flex justify-end items-center w-full pr-8 pt-2">
                    <IconButton onClick={handleToggleSideBar}>
                      <ClearRoundedIcon style={{ fontSize: '32px', color: 'black' }} />
                    </IconButton>
                  </ListItemIcon>
                  <SidebarItem
                    label={t('Create Raffle or Auction')}
                    icon={createRaffleOrAuctionIcon}
                    to="/create-raffle-or-auction/0"
                    onClick={handleToggleSideBar}
                  />
                  {categories.map((item, index) => (
                    <SidebarItem
                      key={index}
                      label={t(`${item?.name?.en}`)}
                      icon={baseURLImage + item?.icon}
                      to={`/&category=${item?._id}`}
                      onClick={handleToggleSideBar}
                    />
                  ))}
                  <SidebarItem label={t('FAQ')} icon={faqIcon} to="/faq" onClick={handleToggleSideBar} />
                  <SidebarItem
                    label={t('Contact Us')}
                    icon={contactUsIcon}
                    to="/contact-us"
                    onClick={handleToggleSideBar}
                  />
                  <SidebarItem label={t('About Us')} icon={aboutUsIcon} to="/about-us" onClick={handleToggleSideBar} />
                </List>
              </Drawer>
              <Drawer open={toggleState.userSideBar} anchor="right" onClose={handleToggleUserSideBar}>
                <List style={{ minWidth: isMdScreen ? '20vw' : '95vw' }}>
                  <Grid container alignItems="center" className="pl-8">
                    <Grid item>
                      <IconButton onClick={handleToggleUserSideBar}>
                        <ClearRoundedIcon style={{ fontSize: '32px', color: 'black' }} />
                      </IconButton>
                    </Grid>
                    <Grid item xs>
                      <div className="flex flex-row justify-evenly items-center px-2">
                        <Typography fontSize={18} align="center" color="#000000" pr={2} mt={0.25}>
                          {user?.firstName} {user?.lastName}
                        </Typography>
                        {<SelectCurrency />}
                      </div>
                    </Grid>
                  </Grid>
                  {menuItems.map((item, index) => (
                    <SidebarItem
                      key={index}
                      label={t(`${item.label}`)}
                      icon={item.icon}
                      to={item.to}
                      onClick={handleToggleUserSideBar}
                      labelAdditionally={
                        item.additionally &&
                        userUnreadMessagesValue !== 0 &&
                        userUnreadMessagesValue !== null &&
                        userUnreadMessagesValue
                      }
                    />
                  ))}
                  <SidebarItem label={t('Logout')} icon={logoutIcon} onClick={handleLogout} />
                </List>
              </Drawer>
            </Toolbar>
          </AppBar>
          <Box className="flex justify-center pt-20 mt-2" style={{ paddingTop: isSgMaxScreen ? 67 : null }}>
            <CustomSearchInput
              className="w-5/6 md:w-1/2 bg-[#F8F8F8] rounded-xl"
              placeholder={t('Search item')}
              variant="outlined"
              name="search"
              value={searchValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              style={{
                width: isMdScreen ? '92vw' : '86vw',
                maxWidth: 865,
              }}
              InputProps={{
                style: {
                  borderRadius: '0.75rem',
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
                autoComplete: 'off',
              }}
              autoComplete="false"
              disabled={
                toggleState.loginDialog ||
                location.pathname === '/login' ||
                location.pathname.startsWith('/email-verification/') ||
                location.pathname.startsWith('/forgot-password')
              }
            />
          </Box>
          <Box className="pl-10 mt-6">
            {!location.pathname.includes('/product/') && location.pathname !== '/' && (
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/">
                  Home
                </Link>

                {/* category view */}
                {location.pathname.includes('category') && !location.pathname.includes('search') && (
                  <Typography color="text.primary">
                    {t(`${categories?.find((cat) => cat?._id === location.pathname.split('=')[1])?.name?.en}`)}
                  </Typography>
                )}

                {/* category view search */}
                {location.pathname.includes('category') && location.pathname.includes('search') && (
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/&category=${location.pathname.split('&')[1].slice(9).slice(0, -1)}`}
                  >
                    {t(
                      `${
                        categories?.find((cat) => cat?._id === location.pathname.split('&')[1].slice(9).slice(0, -1))
                          ?.name?.en
                      }`
                    )}
                  </Link>
                )}
                {location.pathname.includes('category') && location.pathname.includes('search') && (
                  <Typography color="text.primary">{t('Search')}</Typography>
                )}

                {/* subcategory view + search */}
                {location.pathname === '/search/*' && location.search.includes('category') && (
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/&category=${
                      subcategories?.find((subcat) => subcat?._id === location.search.split('&')[1].slice(9))?.parent
                    }`}
                  >
                    {t(
                      `${
                        categories?.find(
                          (cat) =>
                            cat?._id ===
                            subcategories?.find((subcat) => subcat?._id === location.search.split('&')[1].slice(9))
                              ?.parent
                        )?.name?.en
                      }`
                    )}
                  </Link>
                )}
                {location.pathname === '/search/*' && location.search.includes('category') && (
                  <Typography color="text.primary">
                    {t(
                      `${
                        subcategories?.find((subcat) => subcat?._id === location.search.split('&')[1].slice(9))?.name
                          ?.en
                      }`
                    )}
                  </Typography>
                )}
                {/* other search - home, product view, contact & about us, faq */}
                {((!location.pathname.includes('category') &&
                  !location.search.includes('category') &&
                  location.pathname.includes('search')) ||
                  otherPagesPaths.includes(location.pathname.split('/')[1])) && (
                  <Typography color="text.primary">
                    {t(
                      `${
                        otherPagesPaths.includes(location.pathname.split('/')[1])
                          ? location.pathname.split('/')[1].substring(0)[0].toUpperCase() +
                            location.pathname.split('/')[1].slice(1).replace(/-/g, ' ')
                          : 'Search'
                      }`
                    )}
                  </Typography>
                )}
              </Breadcrumbs>
            )}
          </Box>
        </Box>
        {toggleState.loginDialog && (
          <LoginForm
            open={true}
            onClose={() => toggleClick('loginDialog')}
            setLoading={setIsLoadingLoginForm}
            isNeedToLoader={true}
          />
        )}
      </nav>
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
export default Navbar;

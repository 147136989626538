import { createSlice } from '@reduxjs/toolkit';

const userUnreadMessagesSlice = createSlice({
  name: 'userUnreadMessages',
  initialState: {
    unreadMessages: null,
  },
  reducers: {
    setUnreadMessages: (state, action) => {
      state.unreadMessages = action.payload;
    },
  },
});

export const { setUnreadMessages } = userUnreadMessagesSlice.actions;

export const selectUnreadMessages = (state) => state.userUnreadMessages;

export default userUnreadMessagesSlice.reducer;

import { setUser, setUserCurrency, setUserAbbrName } from '../../store/features/userSlice';
import axios from '../../config/axiosConfig';

const performRefreshUser = async (dispatch) => {
  try {
    const response = await axios.get('user/me');
    const userData = response?.data;
    dispatch(setUser(userData));
    dispatch(setUserCurrency(userData?.currency?._id));
    dispatch(setUserAbbrName(userData?.currency?.abbr_name));
    if (!userData?.currency) {
      dispatch(
        setUserCurrency(
          process.env.REACT_APP_NODE_ENV === 'production' ? '6606ac954fbf9c7941c5e7fb' : '65158717bb30bd020a1ca060'
        )
      );
      dispatch(setUserAbbrName('USD'));
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default performRefreshUser;

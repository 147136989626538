import React from 'react';
import Navbar from './components/common/navbar/Navbar.jsx';
import Footer from './components/common/footer/Footer.jsx';
import AppRoutes from './utils/router/Routes.jsx';
import CookieSnackbar from './utils/ui/CookieSnackbarComponent.jsx';
import SupportChat from './components/common/supportChat/SupportChatComponents.jsx';

import './common/styles/main.css';

function App() {
  return (
    <>
      <div className="flex flex-col min-h-screen relative">
        <Navbar />
        <AppRoutes />
        <div className="absolute">
          <SupportChat />
        </div>
        <Footer />
        <CookieSnackbar />
      </div>
    </>
  );
}

export default App;

export function handleChangeFileInputArray(event, selectedFiles, setSelectedFiles, showSnackbar) {
  const newFiles = Array.from(event.target.files);
  const imageFiles = newFiles.filter((file) => file.type.startsWith('image/'));
  if (imageFiles.length !== newFiles.length) {
    showSnackbar(null, 'Only image files are allowed.');
    return;
  }
  const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
  const exceedsLimit = newFiles.some((file) => file.size + totalSize > 8 * 1024 * 1024);
  if (exceedsLimit) {
    showSnackbar(null, 'Total file size exceeds 8 MB limit.');
  } else {
    setSelectedFiles([...selectedFiles, ...imageFiles]);
  }

  event.target.value = null;
}

export function handleChangeFileInput(event, setSelectedFile, showSnackbar) {
  const newFile = Array.from(event.target.files);
  const imageFiles = newFile.filter((file) => file.type.startsWith('image/'));
  if (imageFiles.length !== newFile.length) {
    showSnackbar(null, 'Only image files are allowed.');
    return;
  }
  if (newFile.size > 8 * 1024 * 1024) {
    showSnackbar(null, 'File size exceeds 8 MB limit.');
  } else {
    setSelectedFile(newFile);
  }

  event.target.value = null;
}

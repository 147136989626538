import React from 'react';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries';
import { Typography, Card } from '@mui/material';

const CardComponent = ({ widht, value }) => {
  const { isSgScreen } = useMediaQueries();
  return (
    <Card
      className="flex justify-center items-center text-center"
      sx={{
        mt: 1,
        width: widht || '100%',
        mx: 'auto',
        maxWidth: 420,
        height: 61,
        bgcolor: '#D9D9D91A',
        overflowX: 'auto',
        minWidth: isSgScreen ? 300 : 200,
      }}
      style={{
        borderRadius: '0.75rem',
        boxShadow: '0px 0px 4px 0px #0000001A',
      }}
    >
      <Typography fontWeight={500} fontSize={isSgScreen ? 20 : 18} px={isSgScreen ? 2 : 1} align="center">
        {value}
      </Typography>
    </Card>
  );
};

export default CardComponent;

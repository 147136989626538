import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../config/axiosConfig.js';

import { Typography, Collapse, Card, CardActionArea, CardContent, CardMedia, Chip } from '@mui/material';

import { CustomButton, CustomInputs } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';
import { formatDateTime } from '../../utils/functions/dateTimeFormatter.js';
import { formatNumberWithTwoDecimals } from '../../utils/functions/formatNumberWithTwoDecimals.js';
import SnackbarComponent from '../../utils/ui/SnackbarComponent.jsx';
import { useSnackbar } from '../../utils/ui/js/snackbarUtils.js';
import { useMediaQueries } from '../../utils/ui/js/useMediaQueries.js';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';
import { baseURLImage } from '../../utils/constants.js';

const UnfulfilledItemForm = ({
  onClose,
  productItem,
  orderItem,
  onSuccess,
  isFulfilment,
  amountValue,
  statusValue,
}) => {
  const { t } = useTranslation();
  const { isSdScreen, isSgScreen, isSgMaxScreen } = useMediaQueries();

  const [activeRate, setActiveRate] = useState(null);
  const rateChips = {
    guaranteed_service: { label: 'Guaranteed Service', color: '#008000' },
    trackable: { label: 'Trackable', color: '#1976d2' },
    delivery_days: { label: 'Delivery in day(s):', color: '#FFA500' },
  };
  const ratesOptions = productItem?.rates?.map((r) => (
    <div className="w-full">
      <Card
        className="mb-4"
        style={{
          width: '100%',
          backgroundColor: activeRate === r.rate_id ? '#fdeb92' : null,
        }}
        key={r.rate_id}
        onClick={() => setActiveRate(r.rate_id)}
      >
        <CardActionArea>
          <CardContent>
            <div className="flex justify-around w-full">
              <div className="flex flex-col items-center justify-center w-1/2">
                <Typography align="center" fontSize={isSgMaxScreen ? 16 : 20} px={1}>
                  {t(r.carrier_friendly_name || r.carrier_nickname)}
                </Typography>
                <Typography align="center" fontSize={isSgMaxScreen ? 12 : 16} px={1}>
                  {t(r.service_type)}
                </Typography>
              </div>
              <div className="flex flex-col items-center justify-center w-1/2">
                {Object.keys(rateChips).map(
                  (chip) =>
                    r[chip] && (
                      <Chip
                        label={`${rateChips[chip]?.label}${chip === 'delivery_days' ? ` ${r[chip]}` : ''}`}
                        sx={{ width: isSgMaxScreen ? '100%' : '50%', my: 1, fontSize: 12 }}
                        style={{
                          color: '#fff',
                          overflow: 'visible',
                          background: rateChips[chip].color,
                        }}
                      />
                    )
                )}
              </div>
            </div>
            <div className="flex flex-col w-full mt-2">
              <div className="flex justify-center items-center w-full mb-1">
                <Typography fontSize={isSgMaxScreen ? 14 : 18} width={'50%'}>
                  If shipped today will arrive:
                </Typography>
                <Typography
                  fontSize={isSgMaxScreen ? 14 : 18}
                  fontWeight={300}
                  width={'50%'}
                  sx={{
                    wordWrap: 'break-word',
                  }}
                >
                  {r?.carrier_delivery_days}
                </Typography>
              </div>
              <div className="flex justify-center items-center w-full">
                <Typography fontSize={isSgMaxScreen ? 14 : 18} width={'50%'}>
                  Price:
                </Typography>
                <Typography
                  fontSize={isSgMaxScreen ? 14 : 18}
                  fontWeight={300}
                  width={'50%'}
                  sx={{
                    wordWrap: 'break-word',
                  }}
                >
                  {r.shipping_amount.currency.toUpperCase()}{' '}
                  {(
                    r.shipping_amount.amount +
                    r.insurance_amount.amount +
                    r.confirmation_amount.amount +
                    r.other_amount.amount
                  ).toFixed(2)}
                </Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  ));
  const [isFulfilledItemInputs, setIsFulfilledItemInputs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();

  const isRaffle = productItem?.productInfo?.kind === 'Raffle';

  const [trackingProvider, setTrackingProvider] = useState(null);
  const [trackingProviderError, setTrackingProviderError] = useState('');
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [trackingNumberError, setTrackingNumberError] = useState('');
  const [customShippingProcessing, setCustomShippingProcessing] = useState(false);

  const handleCustomShipping = async () => {
    const fieldIsRequiredMsg = `${t('Field is required')}`;
    let hasError = false;
    const fieldsToCheck = [
      { value: trackingProvider, setError: setTrackingProviderError },
      { value: trackingNumber, setError: setTrackingNumberError },
    ];

    fieldsToCheck.forEach(({ value, setError }) => {
      if (!value || (typeof value === 'string' && !value.trim())) {
        setError(fieldIsRequiredMsg);
        hasError = true;
      }
    });

    if (hasError) return;

    try {
      setCustomShippingProcessing(true);
      await axios.post('delivery/custom-shipment', {
        productId: productItem._id,
        trackingProvider,
        trackingNumber,
      });
      onSuccess();
    } catch (error) {
      showSnackbar(error);
    } finally {
      setCustomShippingProcessing(false);
    }
  };

  const handleFulfillItem = async () => {
    setIsLoading(true);
    let customer = productItem?.customer;
    if (isRaffle) Object.assign(customer?.user, { ...productItem?.user });
    axios
      .put(`product/${productItem?._id}/add-shipping-label`, {
        rateId: activeRate,
        customer,
      })
      .then(() => {
        onSuccess();
        setIsLoading(false);
      })
      .catch((error) => {
        showSnackbar(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <FormTemplate
        open={true}
        onClose={onClose}
        dialogTitle={
          <>
            <div className="flex justify-center">
              <Card
                className="ml-2"
                sx={{
                  maxWidth: isSgScreen ? 300 : isSdScreen ? 200 : 180,
                  maxHeight: isSgScreen ? 300 : isSdScreen ? 200 : 180,
                  borderRadius: '0.75rem',
                  width: '100%',
                  boxShadow: '0px 4px 4px 0px #00000040',
                }}
              >
                <div className="flex items-center justify-center w-full">
                  <CardMedia
                    className="justify-center items-center w-full"
                    sx={{
                      maxWidth: isSgScreen ? 300 : isSdScreen ? 200 : 180,
                      height: isSgScreen ? 300 : isSdScreen ? 200 : 180,
                      display: 'flex',
                    }}
                    image={baseURLImage + productItem?.media?.[0]}
                  >
                    {!productItem?.media[0] && (
                      <Typography align="center" fontSize={16} px={1}>
                        {t('No image')}
                      </Typography>
                    )}
                  </CardMedia>
                </div>
              </Card>
            </div>
          </>
        }
        dialogContent={
          <>
            <Typography
              align="start"
              className="px-4 sm:px-8"
              fontSize={isSgScreen ? 24 : isSdScreen ? 20 : 18}
              fontWeight={500}
              width="100%"
              sx={{
                wordWrap: 'break-word',
              }}
            >
              {`${t('Order')}: #${isFulfilment ? productItem?.customer?._id : orderItem?._id}`}
            </Typography>
            <div className="mt-4 w-full px-4">
              {[
                { label: 'Item:', value: productItem?.title },
                { label: 'Type:', value: productItem?.productInfo?.kind },
                isFulfilment
                  ? {
                      label: isRaffle ? 'Amount:' : 'Bid:',
                      value: isRaffle
                        ? `${productItem?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
                            productItem?.customer?.amount * productItem?.converter?.price
                          )}`
                        : `${productItem?.orderList?.[0]?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
                            productItem?.orderList?.[0]?.converter?.price
                          )}`,
                    }
                  : {
                      label: isRaffle ? 'Amount:' : 'Your Bid:',
                      value: amountValue
                        ? amountValue
                        : `${productItem?.converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
                            orderItem?.converter?.price * orderItem?.amount
                          )}`,
                    },
                {
                  label: 'Status:',
                  value: statusValue
                    ? statusValue
                    : isFulfilment
                      ? productItem?.status
                      : orderItem?.status === 'pending'
                        ? 'Payment processing'
                        : 'Paid',
                },
                {
                  label: 'Date & time:',
                  value: formatDateTime(productItem?.updatedAt).end,
                },
                isFulfilment && {
                  label: `${isRaffle ? 'Winner' : 'Seller'}:`,
                  value: isRaffle
                    ? `${productItem?.customer?.user?.firstName} ${productItem?.customer?.user?.lastName}`
                    : `${productItem?.user?.firstName} ${productItem?.user?.lastName}`,
                },
                isFulfilment && {
                  label: `${isRaffle ? 'Winner' : 'Seller'}'s Tel. No.:`,
                  value: isRaffle ? productItem?.customer?.user?.phone : productItem?.user?.phone,
                },
                isFulfilment && {
                  label: `${isRaffle ? 'Winner' : 'Seller'}'s E-mail:`,
                  value: isRaffle ? productItem?.customer?.user?.email : productItem?.user?.email,
                },
                isFulfilment && {
                  label: 'Delivery',
                  value: null,
                },
                isFulfilment && {
                  label: 'Street Address:',
                  value: productItem?.delivery?.street,
                },
                isFulfilment && {
                  label: 'City:',
                  value: productItem?.delivery?.city,
                },
                isFulfilment && {
                  label: 'State/Province:',
                  value: productItem?.delivery?.state,
                },
                isFulfilment && {
                  label: 'Postal/ZIP Code:',
                  value: productItem?.delivery?.postalCode,
                },
                isFulfilment && {
                  label: 'Country:',
                  value: productItem?.delivery?.country,
                },
              ]
                .filter(Boolean)
                ?.map(({ label, value }, index) => (
                  <div key={index} className="mt-4 flex flex-row w-full sm:px-4">
                    <Typography fontSize={20} width={'40%'}>
                      {label}
                    </Typography>
                    <Typography
                      ml={2}
                      fontSize={20}
                      fontWeight={300}
                      width={'60%'}
                      sx={{
                        wordWrap: 'break-word',
                      }}
                    >
                      {value}
                    </Typography>
                  </div>
                ))}
            </div>
            {isFulfilment && (
              <Collapse in={!!isFulfilledItemInputs} className="mt-8 text-center w-full">
                <div className="flex flex-col items-center w-full my-4 px-4">
                  {ratesOptions}
                  {isRaffle && (
                    <div className="w-full">
                      <Card className="mb-4" style={{ width: '100%', cursor: 'default' }}>
                        <CardActionArea disableRipple disableFocusRipple>
                          <CardContent>
                            <div className="flex justify-around w-full">
                              <Typography align="center" fontSize={isSgMaxScreen ? 16 : 20} px={1}>
                                {t('Use own shipping')}
                              </Typography>
                              <Typography align="center" fontSize={isSgMaxScreen ? 12 : 16} px={1}>
                                {t(
                                  `You can add manually shipped carrier and tracking number.${productItem?.internationalShipment ? " Don't forget to include customs declaration with your item during the shipment." : ''}`
                                )}
                              </Typography>
                            </div>
                            <div className="flex flex-col w-full mt-2">
                              <div className="flex justify-between items-center max-[435px]:flex-col max-[435px]:items-center w-full mb-1">
                                <CustomInputs
                                  style={{ width: isSgMaxScreen ? '100%' : '48%' }}
                                  placeholder={t('Carrier')}
                                  variant="outlined"
                                  helperText={trackingProviderError ? trackingProviderError : ' '}
                                  error={!!trackingProviderError}
                                  value={trackingProvider}
                                  onChange={(e) => {
                                    setTrackingProvider(e.target.value);
                                    setTrackingProviderError('');
                                  }}
                                />
                                <CustomInputs
                                  style={{ width: isSgMaxScreen ? '100%' : '48%' }}
                                  placeholder={t('Tracking Number')}
                                  variant="outlined"
                                  helperText={trackingNumberError ? trackingNumberError : ' '}
                                  error={!!trackingNumberError}
                                  value={trackingNumber}
                                  onChange={(e) => {
                                    setTrackingNumber(e.target.value);
                                    setTrackingNumberError('');
                                  }}
                                />
                              </div>
                              <div className="flex justify-center items-center w-full">
                                <CustomButton
                                  variant="contained"
                                  width={10}
                                  style={{
                                    width: isSdScreen ? null : 180,
                                  }}
                                  onClick={() => handleCustomShipping()}
                                >
                                  <LoadingIndicator
                                    isLoading={customShippingProcessing}
                                    value={<>{t('confirm custom shipping')}</>}
                                  />
                                </CustomButton>
                              </div>
                            </div>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </div>
                  )}
                </div>
              </Collapse>
            )}
          </>
        }
        dialogContentPadding={0}
        dialogActions={
          isFulfilment ? (
            <>
              <CustomButton
                variant="contained"
                width={10}
                style={{
                  width: isSdScreen ? null : 180,
                }}
                onClick={
                  !isFulfilledItemInputs
                    ? () => {
                        setIsFulfilledItemInputs(true);
                      }
                    : handleFulfillItem
                }
                disabled={productItem?.status !== 'unfulfilled' || isLoading || (isFulfilledItemInputs && !activeRate)}
              >
                <LoadingIndicator
                  isLoading={isLoading}
                  value={<>{t(!isFulfilledItemInputs ? 'pick shipping' : 'get payment link via email')}</>}
                />
              </CustomButton>
            </>
          ) : null
        }
      />
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default UnfulfilledItemForm;

import { setUser } from '../../store/features/userSlice';
import { setAccessToken, setRefreshToken } from '../../store/features/authSlice';
import { setPreviousUrl } from '../../store/features/urlSlice';
import { setUserPrepopulateAddress } from '../../store/features/userPrepopulateAddress';
import { setIsUserAccountSeller } from '../../store/features/userAccountSellerSlice';

const performLogout = async (dispatch) => {
  const logoutActions = [
    dispatch(setAccessToken(null)),
    dispatch(setRefreshToken(null)),
    dispatch(setPreviousUrl(null)),
    dispatch(setUser(null)),
    dispatch(setUserPrepopulateAddress(null)),
    dispatch(setIsUserAccountSeller(false)),
  ];

  await Promise.all(logoutActions);
};
export default performLogout;

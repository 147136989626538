import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './features/authSlice';
import userReducer from './features/userSlice';
import urlReducer from './features/urlSlice';
import toggleFormsReducer from './features/toggleFormsSlice';
import userPrepopulateAddressReducer from './features/userPrepopulateAddress';
import userAccountSellerReducer from './features/userAccountSellerSlice';
import cookieReducer from './features/cookieSlice';
import userUnreadMessagesReducer from './features/userUnreadMessagesSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  url: urlReducer,
  toggleForms: toggleFormsReducer,
  userPrepopulateAddress: userPrepopulateAddressReducer,
  userAccountSeller: userAccountSellerReducer,
  cookie: cookieReducer,
  userUnreadMessages: userUnreadMessagesReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;

<template>
  <div>
    <Chat :userId="this.userId" :isSupportChat="this.isSupportChat" />
  </div>
</template>

<script>
import Chat from './Chat/ChatComponent.vue';

export default {
  name: 'App',
  components: {
    Chat,
  },
  props: {
    userId: String,
    isSupportChat: Boolean,
  },
};
</script>

<style scoped>
@import '../../common/styles/font.css';
@import '../../index.css';
#div {
  display: flex;
  min-height: auto;
}
</style>

import React, { useState, useRef, useEffect } from 'react';
import { useMediaQueries } from './js/useMediaQueries';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/thumbs';

import CustomCard from './CardComponent.jsx';

function SwiperComponent({
  data,
  isOnlyImages,
  isLoading,
  isThumb,
  isDivideSlidePerView,
  isSlidePerViewAuto,
  slidePerView,
  sliderWidth,
  sliderOffset,
  leftCenteredSlides,
}) {
  const { isMdScreen, isLgScreen, isXlScreen } = useMediaQueries();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef = useRef(null);
  const [slideWidth, setSlideWidth] = useState(0);
  const thumbSwiperRef = useRef(null);
  const [thumbSlideWidth, setThumbSlideWidth] = useState(0);

  useEffect(() => {
    if (swiperRef.current) {
      const slideElement = swiperRef.current.swiper.wrapperEl.querySelector('.swiper-slide');
      if (slideElement) {
        const width = slideElement.offsetWidth;
        setSlideWidth(width);
      }
    }
    if (thumbSwiperRef.current) {
      const slideElement = thumbSwiperRef.current.swiper.wrapperEl.querySelector('.swiper-slide');
      if (slideElement) {
        const width = slideElement.offsetWidth;
        setThumbSlideWidth(width);
      }
    }
  }, [swiperRef]);

  const calculateSlidesPerView = () => {
    if (isSlidePerViewAuto) {
      return 'auto';
    } else if (isThumb) {
      return 1;
    } else {
      const screenWidth = window.innerWidth;
      const offset = (screenWidth * (sliderOffset ? sliderOffset : 10)) / 100;
      const slidesPerView =
        screenWidth <= 435 ? (screenWidth - offset) / sliderWidth : (screenWidth - offset) / sliderWidth;
      const baseValue = slidePerView ? slidePerView : slidesPerView;
      const factor = isDivideSlidePerView ? 2 : 1;
      return baseValue / factor;
    }
  };

  const swiperParams = {
    slidesPerView: slidePerView ? slidePerView : calculateSlidesPerView(),
    autoplay: {
      delay: isThumb ? 4500 : 2500,
      disableOnInteraction: true,
    },
    spaceBetween: 10,
    grabCursor: true,
    centeredSlides: !isMdScreen,
    centeredSlidesBounds: leftCenteredSlides,
    modules: [Autoplay, Thumbs],
    thumbs: {
      swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
    },
  };

  return (
    <>
      <Swiper
        ref={swiperRef}
        {...swiperParams}
        style={{
          overflowY: 'visible',
          padding: `0 ${leftCenteredSlides ? '1%' : '12px'} 0 ${leftCenteredSlides ? '0' : '12px'}`,
        }}
      >
        {data.map((item, index) => (
          <SwiperSlide
            key={index}
            className="justify-center pb-2 pt-1 h-fit"
            style={{ display: 'flex', minWidth: sliderWidth ? sliderWidth : isLgScreen ? 350 : 280 }}
          >
            <CustomCard
              item={item}
              index={index}
              isOnlyImages={isOnlyImages}
              isLoading={isLoading}
              minWidth={sliderWidth ? sliderWidth : isLgScreen ? 350 : 280}
              maxWidth={sliderWidth ? sliderWidth : null}
              width={'100%'}
              height={slideWidth}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {isThumb && (
        <>
          <div className="mt-4">
            <Swiper
              ref={thumbSwiperRef}
              onSwiper={setThumbsSwiper}
              spaceBetween={0}
              slidesPerView={isXlScreen ? 5 : isLgScreen ? 4 : 3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
              modules={[Autoplay, Thumbs]}
              grabCursor={true}
              freeMode={true}
            >
              {data.map((item, index) => (
                <SwiperSlide key={`thumb_${index}`} className="pb-2 justify-center" style={{ display: 'flex' }}>
                  <CustomCard
                    item={item}
                    index={index}
                    isOnlyImages={isOnlyImages}
                    noOpenImages={true}
                    isLoading={isLoading}
                    width={'100%'}
                    margin={'0 5px'}
                    height={thumbSlideWidth - 10}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
    </>
  );
}

export default SwiperComponent;

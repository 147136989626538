import React, { useCallback, useEffect, useState } from 'react';
import axios from '../../config/axiosConfig';
import { useMediaQueries } from './js/useMediaQueries';
import { Box, InputAdornment, MenuItem, Skeleton, IconButton, InputBase } from '@mui/material';
import { CustomInputs, CustomSelect } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

const SelectWithCurrency = ({
  placeholder,
  type,
  sx,
  style,
  selectedCurrency,
  handleChange,
  setError,
  error,
  setSelectedCurrency,
  value,
  setIsDisabledBidButton,
  minimalBid,
  step,
  handleAddStep,
  handleSubtractStep,
  isDisabledSelectedCurrency,
  currencyAbrName,
  isReadOnly,
}) => {
  const { isSdScreen } = useMediaQueries();
  const [currency, setCurrency] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const handleFetchData = useCallback(async () => {
    if (!isDisabledSelectedCurrency) {
      setLoadingData(true);
      try {
        const currencyResponse = await axios.get('currency');
        setCurrency(currencyResponse.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingData(false);
      }
    }
  }, [isDisabledSelectedCurrency]);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);
  return (
    <CustomInputs
      placeholder={placeholder}
      variant="outlined"
      type={type}
      sx={sx}
      style={style}
      value={value ?? minimalBid ?? ''}
      onWheel={(e) => e.target.blur()}
      onChange={(e) => {
        if (step === null || step === undefined) {
          handleChange(e.target.value);
        }
        if (minimalBid !== null && e.target.value < minimalBid) {
          setError(`Seller has set minimal bid for this item. Value must be at least ${minimalBid}`);
          setIsDisabledBidButton(true);
        } else {
          setError('');
          minimalBid && setIsDisabledBidButton(false);
        }
      }}
      InputProps={{
        inputComponent: InputBase,
        inputProps: {
          readOnly: !!isReadOnly,
          sx: { display: 'flex' },
        },
        startAdornment: (
          <InputAdornment position="start" sx={{ color: 'black' }}>
            {isDisabledSelectedCurrency ? (
              <>{currencyAbrName}</>
            ) : (
              <>
                {loadingData ? (
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="100%"
                    variant="text"
                    sx={{
                      fontSize: '21.5rem',
                      mr: 2,
                    }}
                  />
                ) : (
                  <>
                    <CustomSelect
                      value={isDisabledSelectedCurrency ? currencyAbrName : selectedCurrency}
                      onChange={(e) => setSelectedCurrency(e.target.value)}
                      displayEmpty
                      style={{
                        width: '100%',
                      }}
                      readOnly={(minimalBid && minimalBid !== null) || isDisabledSelectedCurrency}
                      IconComponent={minimalBid && minimalBid !== null ? () => null : undefined}
                    >
                      {isDisabledSelectedCurrency ? (
                        <MenuItem value={currencyAbrName}>{currencyAbrName}</MenuItem>
                      ) : (
                        currency.map((currency, index) => (
                          <MenuItem key={currency?._id} value={currency?._id}>
                            {currency?.icon} {currency?.abbr_name}
                          </MenuItem>
                        ))
                      )}
                    </CustomSelect>
                  </>
                )}
              </>
            )}
            <Box
              sx={{
                height: 40,
                borderLeft: '1px solid #00000080',
                mr: isSdScreen ? 1 : 0,
                ml: 2,
              }}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {step != null && (
              <>
                <IconButton onClick={handleAddStep} sx={{ padding: isSdScreen ? null : 0 }}>
                  <AddRoundedIcon
                    style={{
                      fontSize: isSdScreen ? '24px' : '20px',
                      color: 'black',
                    }}
                  />
                </IconButton>
                <IconButton onClick={handleSubtractStep} sx={{ padding: isSdScreen ? null : 0 }}>
                  <RemoveRoundedIcon
                    style={{
                      fontSize: isSdScreen ? '24px' : '20px',
                      color: 'black',
                    }}
                  />
                </IconButton>
              </>
            )}
          </>
        ),
      }}
      helperText={error ? error : ' '}
      error={!!error}
    />
  );
};

export default SelectWithCurrency;

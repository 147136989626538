import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Popover, Typography } from '@mui/material';

const StyledCustomInputs = styled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#6c25be',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
      transition:
        'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s, border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B2BAC2',
    },
  },
});

const StyledCustomPopover = styled(Popover)(({ padding }) => ({
  '& .MuiPopover-paper': {
    borderRadius: '1.25rem',
    padding: padding || 16,
    whiteSpace: 'nowrap',
  },
}));
const StyledCustomTextTypography = styled(Typography)(({ fontSize }) => ({
  fontWeight: 500,
  fontSize: fontSize || 20,
}));

const CustomSearchInput = (props) => {
  return <StyledCustomInputs {...props} />;
};

const CustomPopover = ({ children, ...props }) => {
  return <StyledCustomPopover {...props}>{children}</StyledCustomPopover>;
};
const CustomTextTypography = (props) => {
  return <StyledCustomTextTypography {...props} />;
};

export { CustomSearchInput, CustomPopover, CustomTextTypography };

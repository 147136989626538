import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Divider } from '@mui/material';

import accordionsData from './AccordionText.js';
import AccordionComponent from '../../utils/ui/AccordionComponent.jsx';

import faqIcon from '../../assets/icons/faqIcon.svg';

function FaqPage() {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-center px-4 py-8 min-[435px]:py-10 min-[435px]:px-6 sm:p-10 lg:p-20 xl:mx-20">
        <div>
          <div className="flex flex-row items-center">
            <img src={faqIcon} alt="Hello Icon" style={{ width: '30px' }} />
            <Typography fontWeight={600} fontSize={20} ml={2}>
              {t('FAQ')}
            </Typography>
          </div>
          <div className="mt-6 max-w-7xl">
            <Divider />
            <div className="my-6">
              {accordionsData.map((accordion, index) => (
                <div key={index}>
                  <div className="my-6">
                    <AccordionComponent title={accordion.title} content={accordion.content} />
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqPage;

import React, { useState } from 'react';

export const useSnackbar = () => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

  const showSnackbar = (error, defaultErrorMessage = 'Something went wrong :(', severity = 'error') => {
    setIsOpenSnackbar(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(error?.response?.data?.message || defaultErrorMessage);
  };

  const closeSnackbar = () => {
    setIsOpenSnackbar(false);
  };

  return {
    isOpenSnackbar,
    snackbarMessage,
    snackbarSeverity,
    showSnackbar,
    closeSnackbar,
  };
};

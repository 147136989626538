import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TooltipButton from '../../utils/ui/TooltipComponent.jsx';
import { formatNumberWithTwoDecimals } from '../../utils/functions/formatNumberWithTwoDecimals';

function RenderPrice({ currency, defaultCurrency, converter, isRaffle, minimal }) {
  const { t } = useTranslation();

  const getDisplayedPrice = (minimal, isRaffle, converter) => {
    if (minimal) {
      return converter?.minimum;
    } else if (isRaffle) {
      return converter?.price;
    } else {
      return converter?.buy_now;
    }
  };

  return (
    <div>
      <Typography component="div" fontSize={19} fontWeight={300} className="flex">
        <span style={{ fontWeight: 500 }} className="ml-2">
          {`${converter?.currency?.abbr_name} ${formatNumberWithTwoDecimals(
            getDisplayedPrice(minimal, isRaffle, converter)
          )}`}
        </span>
        {defaultCurrency !== currency?._id && (
          <TooltipButton
            title={t(
              'Converted amount displayed in your selected currency is a representation of an average market share of correlation between your selected currency and original product currency price set by seller. Real payment amount can vary, please also consider services fees, double-conversion or other operations depending on your payment method and currency.'
            )}
          />
        )}
      </Typography>
    </div>
  );
}

export default RenderPrice;

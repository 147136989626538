import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Fab, Slide } from '@mui/material';
import { selectUser } from '../../../store/features/userSlice';
import axios from '../../../config/axiosConfig';
import ChatPage from '../../../vue/vue-integrations/VueWrapper.js';
import { useSnackbar } from '../../../utils/ui/js/snackbarUtils';
import SnackbarComponent from '../../../utils/ui/SnackbarComponent.jsx';
import eventBus from '../../../utils/functions/eventBus.js';
import { supportUserId } from '../../../utils/constants';

import messagesIcon from '../../../assets/icons/messagesIcon.svg';
import './supportChatStyle.css';

function SupportChat() {
  const user = useSelector(selectUser);
  const location = useLocation();
  const { isOpenSnackbar, snackbarMessage, snackbarSeverity, showSnackbar, closeSnackbar } = useSnackbar();

  const [isOpenedSupportChat, setIsOpenedSupportChat] = useState(false);
  const [isShowChatSupport, setIsShowChatSupport] = useState(location.pathname !== '/chat');
  const [isAnimation, setIsAnimation] = useState(false);

  const handleCreateSupportChat = () => {
    axios
      .post(`chat`, {
        users: [user?._id, supportUserId],
      })
      .then(() => {
        setIsOpenedSupportChat(true);
      })
      .catch((error) => {
        if (error?.response?.data?.message === 'Chat already exists' || error?.response?.data?.statusCode === 400) {
          setIsAnimation(true);
          setTimeout(() => {
            setIsOpenedSupportChat(true);
          }, 300);
        } else {
          showSnackbar(error);
        }
      });
  };
  useEffect(() => {
    const updateIsOpenedSupportChat = (value) => {
      setIsAnimation(false);
      setTimeout(() => {
        setIsOpenedSupportChat(value);
      }, 500);
    };
    eventBus.$on('closeSupportChat', updateIsOpenedSupportChat);
    return () => {
      eventBus.$off('closeSupportChat', updateIsOpenedSupportChat);
    };
  }, []);

  useEffect(() => {
    setIsShowChatSupport(location.pathname !== '/chat');
  }, [location]);

  return (
    <>
      <div className="flex relative flex-col " style={{ maxHeight: '50%' }}>
        {isShowChatSupport && user?._id !== null && (
          <>
            {isOpenedSupportChat ? (
              <>
                <div>
                  <div
                    key="support-chat-key"
                    style={{ zIndex: 10000 }}
                    className={`w-full max-w-[400px] fixed bottom-[-2%] right-0 chat-bubble ${!isAnimation ? 'closed' : ''}`}
                  >
                    <ChatPage
                      componentProps={{
                        userId: user?._id,
                        isSupportChat: true,
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`fixed bottom-10 right-5 ${isAnimation ? 'chat-bnt-bubble' : ''}`}
                  style={{ zIndex: 1000 }}
                >
                  <Slide direction={'up'} in={!isOpenedSupportChat} timeout={500}>
                    <Fab style={{ backgroundColor: '#fff' }} aria-label="create chat" onClick={handleCreateSupportChat}>
                      <img src={messagesIcon} alt="support icon" style={{ width: '30px' }} />
                    </Fab>
                  </Slide>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <SnackbarComponent
        open={isOpenSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default SupportChat;

import { createSlice } from '@reduxjs/toolkit';

const userPrepopulateAddressSlice = createSlice({
  name: 'userPrepopulateAddress',
  initialState: {
    country: null,
    city: null,
    postalCode: null,
    continentCode: null,
    currency: null,
  },
  reducers: {
    setUserPrepopulateAddress: (state, { payload }) => {
      if (payload) {
        const { country, city, postalCode, continentCode, currency } = payload;
        state.country = country;
        state.city = city;
        state.postalCode = postalCode;
        state.continentCode = continentCode;
        state.currency = currency;
      } else {
        state.country = state.city = state.postalCode = state.continentCode = state.currency = null;
      }
    },
  },
});

export const { setUserPrepopulateAddress } = userPrepopulateAddressSlice.actions;

export const selectUserPrepopulateAddress = (state) => state.userPrepopulateAddress;

export default userPrepopulateAddressSlice.reducer;

import React from 'react';
import emailIcon from '../../../assets/icons/emailIcon.svg';

const SuccessDialogTexts = ({ isBid, isBuyItNow, t, order }) => {
  const getDialogTitle = () => {
    const content = isBid
      ? t('Thank you for placing your bid!')
      : isBuyItNow
        ? t('Thank you for your Order!')
        : t('Thank you for taking a chance!');

    return <span className="flex flex-row items-center justify-center">{content}</span>;
  };

  const getDialogContent = () => {
    const content = isBid ? (
      <>
        {t(
          "Your bid will be lead until it is outbid by another user. Once outbid, we will notify you via email (don't forget to check the spam folder)!"
        )}
      </>
    ) : isBuyItNow ? (
      <>
        {t(`Order number: ${order?._id}`)}{' '}
        <span className="flex flex-row items-center justify-center mt-4">
          {t('We have sent you an order confirmation to your email. Don’t forget to check the spam folder!')}{' '}
          <img src={emailIcon} alt="Email Icon" style={{ width: 30, height: 30 }} />
        </span>
      </>
    ) : (
      <>{t('You will receive an email when the Raffle is over! Don’t forget to check the spam folder!')} </>
    );
    return (
      <>
        {content}
        <div className="text-center mt-4">
          {t(`${isBid || isBuyItNow ? 'Auction' : 'Raffle'} will be updated as soon as ${isBid ? 'bid' : 'payment'} will be processed by
          system. Don’t forget to refresh the page some time after to see the
          confirmed data`)}
          .
        </div>
      </>
    );
  };

  return {
    title: getDialogTitle(),
    content: getDialogContent(),
  };
};

export default SuccessDialogTexts;

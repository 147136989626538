import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../config/axiosConfig';
import { Typography } from '@mui/material';

import { CustomButton } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';
import LoadingIndicator from '../../utils/ui/LoadingIndicator.jsx';
import EmailForm from './EmailForm.jsx';
import FormTemplate from '../../utils/ui/forms/FormTemplate.jsx';

const ForgotPassword = ({ open, onClose, onError, email, goBack }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const handleForgotPassword = async () => {
    setIsLoading(true);
    axios
      .post('auth/forgot-password', {
        email: email,
      })
      .then(function (response) {
        setIsLoading(false);
        setSendEmail(true);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.error(error);
        onError(error?.response?.data?.message);
      });
  };

  const handleCloseForm = () => {
    if (onClose) {
      onClose();
    }
    navigate('/');
  };
  return (
    <>
      {!sendEmail ? (
        <FormTemplate
          open={open}
          onClose={handleCloseForm}
          isGoBack={goBack}
          dialogTitle={
            <>
              <Typography component="div" mt={2} align="center" variant="h4">
                {t('Forgot Password')}
              </Typography>
            </>
          }
          dialogContent={
            <>
              <Typography variant="h6" sx={{ mt: 4, textAlign: 'center', fontWeight: 300 }}>
                {t('We will send you an email to')} <span className="font-medium">{email}</span>{' '}
                {t('with a password recovery link')}.
              </Typography>
            </>
          }
          dialogActions={
            <CustomButton variant="contained" disabled={isLoading} onClick={handleForgotPassword}>
              <LoadingIndicator isLoading={isLoading} value={t('Send email')} />
            </CustomButton>
          }
        />
      ) : (
        <>
          <EmailForm
            open={open}
            onClose={handleCloseForm}
            mainText={t('We have send you an email with a password recovery link.')}
            addionalText={t(
              'If you have not received an email in your inbox please check your spam folder or make sure you have entered the correct email address.'
            )}
          />
        </>
      )}
    </>
  );
};

export default ForgotPassword;

module.exports = {
  resources: {
    en: {
      translation: require('./assets/localization/en.json'),
    },
    ua: {
      translation: require('./assets/localization/ua.json'),
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
};

import React from 'react';
import { useMediaQueries } from './js/useMediaQueries';
import { Box, InputAdornment } from '@mui/material';
import { CustomInputs } from '../../common/styles/customStyledComponents/customStyledComponents.jsx';

const InputWithCurrency = ({
  value,
  placeholder,
  type,
  sx,
  style,
  currencyAbrName,
  handleChange,
  setError,
  error,
  isReadOnly,
}) => {
  const { isSdScreen } = useMediaQueries();
  return (
    <CustomInputs
      placeholder={placeholder}
      variant="outlined"
      type={type}
      sx={sx}
      style={style}
      value={value}
      onChange={(e) => {
        handleChange(e.target.value);
        setError('');
      }}
      onWheel={(e) => e.target.blur()}
      InputProps={{
        readOnly: isReadOnly,
        startAdornment: (
          <InputAdornment position="start" sx={{ color: 'black' }}>
            {currencyAbrName}
            <Box
              sx={{
                height: 40,
                borderLeft: '1px solid #00000080',
                mr: isSdScreen ? 1 : 0,
                ml: 2,
              }}
            />
          </InputAdornment>
        ),
      }}
      helperText={error ? error : ' '}
      error={!!error}
    />
  );
};

export default InputWithCurrency;
